import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { format, isWithinInterval, parse } from 'date-fns';
import { toast } from 'react-toastify';
import * as Icons from 'react-icons/fa';
import '../styles/CharityCards.css';

// Constants
const INITIAL_FILTERS = {
  location: {
    state: '',
    city: '',
    zipcode: '',
    radius: 25,
    coordinates: null
  },
  hours: {
    currentlyOpen: false,
    weekends: false,
    evenings: false
  },
  items: [],
  sortBy: 'distance',
  searchTerm: '',
  showFilters: true
};

const RADIUS_OPTIONS = [
  { value: 5, label: '5 miles' },
  { value: 10, label: '10 miles' },
  { value: 25, label: '25 miles' },
  { value: 50, label: '50 miles' },
  { value: 100, label: '100 miles' },
  { value: 200, label: '200 miles' },
  { value: 500, label: '500 miles' }
];

const SORT_OPTIONS = [
  { value: 'name', label: 'Name (A-Z)' },
  { value: 'distance', label: 'Distance' },
  { value: 'recent', label: 'Recently Added' }
];

// Utility Functions
const utils = {
  calculateDistance: (lat1, lon1, lat2, lon2) => {
    if (!lat1 || !lon1 || !lat2 || !lon2) return null;
    
    const R = 3959; // Earth's radius in miles
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    return R * c;
  },

  parseTimeString: (timeStr) => {
    if (!timeStr || typeof timeStr !== 'string') return null;
    if (timeStr.toLowerCase() === 'closed') return null;
    
    try {
      const [start, end] = timeStr.split('-').map(t => t.trim());
      if (!start || !end) return null;

      // Parse times in 12-hour format
      const parseTime = (timeStr) => {
        const match = timeStr.match(/^(\d{1,2})(?::(\d{2}))?\s*(AM|PM)$/i);
        if (!match) return null;

        let [_, hours, minutes, period] = match;
        hours = parseInt(hours);
        minutes = parseInt(minutes || '0');

        // Convert to 24-hour format
        if (period.toUpperCase() === 'PM' && hours !== 12) hours += 12;
        if (period.toUpperCase() === 'AM' && hours === 12) hours = 0;

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
      };

      const startTime = parseTime(start);
      const endTime = parseTime(end);

      if (!startTime || !endTime) return null;

      return { start: startTime, end: endTime };
    } catch (error) {
      console.error('Error parsing time string:', error);
      return null;
    }
  },

  hasEveningHours: (operatingHours) => {
    if (!operatingHours || typeof operatingHours !== 'object') return false;

    return Object.values(operatingHours).some(hours => {
      if (!hours || hours.toLowerCase() === 'closed') return false;
      
      const times = utils.parseTimeString(hours);
      if (!times) return false;

      const endHour = times.end.getHours();
      return endHour > 17;
    });
  },

  hasWeekendHours: (operatingHours) => {
    if (!operatingHours || typeof operatingHours !== 'object') return false;

    const saturdayOpen = operatingHours.saturday && 
                        operatingHours.saturday.toLowerCase() !== 'closed';
    const sundayOpen = operatingHours.sunday && 
                      operatingHours.sunday.toLowerCase() !== 'closed';

    return saturdayOpen || sundayOpen;
  },

  checkIfCurrentlyOpen: (operatingHours) => {
    if (!operatingHours) return false;

    const now = new Date();
    const currentDay = now.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();
    const hoursToday = operatingHours[currentDay];
    
    if (!hoursToday || hoursToday.toLowerCase() === 'closed') return false;

    const times = utils.parseTimeString(hoursToday);
    if (!times) return false;

    const currentTime = now.getTime();
    return currentTime >= times.start.getTime() && currentTime <= times.end.getTime();
  }
};

// Custom Hooks
const useGeolocation = () => {
  const [state, setState] = useState({
    coordinates: null,
    isLoading: false,
    error: null
  });

  const getLocation = useCallback(async () => {
    if (!navigator.geolocation) {
      setState(prev => ({ ...prev, error: 'Geolocation is not supported' }));
      return;
    }

    setState(prev => ({ ...prev, isLoading: true }));
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      setState({
        coordinates: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        },
        isLoading: false,
        error: null
      });
    } catch (error) {
      setState({
        coordinates: null,
        isLoading: false,
        error: error.message
      });
    }
  }, []);

  useEffect(() => {
    getLocation();
  }, [getLocation]);

  return { ...state, getLocation };
};

// Reusable Components
const IconButton = ({ icon: IconComponent, onClick, label, className = '', disabled = false }) => (
  <button
    onClick={onClick}
    className={`icon-button ${className}`}
    aria-label={label}
    disabled={disabled}
  >
    <IconComponent />
  </button>
);

const StatusBadge = ({ isOpen }) => (
  <div className={`status-badge ${isOpen ? 'open' : 'closed'}`}>
    {isOpen ? 'Open' : 'Closed'}
  </div>
);

const LoadingSpinner = () => (
  <Icons.FaSpinner className="spinner" aria-label="Loading..." />
);

const DynamicItemTags = ({ items }) => {
  const [visibleTags, setVisibleTags] = useState([]);
  const [hiddenCount, setHiddenCount] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const calculateVisibleTags = () => {
      if (!containerRef.current || !items.length) return;

      // Constants from CSS
      const ROW_HEIGHT = 28; // height of each tag
      const VERTICAL_GAP = 8; // gap between rows
      const HORIZONTAL_GAP = 8; // gap between tags
      const MAX_ROWS = 2;
      const CONTAINER_HEIGHT = (ROW_HEIGHT * MAX_ROWS) + VERTICAL_GAP;
      
      // Get container width
      const containerWidth = containerRef.current.clientWidth - HORIZONTAL_GAP; // Account for edge margin

      // Create measurement div
      const measureDiv = document.createElement('div');
      measureDiv.style.position = 'absolute';
      measureDiv.style.visibility = 'hidden';
      measureDiv.style.height = ROW_HEIGHT + 'px';
      measureDiv.className = 'item-tag';
      document.body.appendChild(measureDiv);

      // Measure more tag first
      measureDiv.textContent = '+999';
      const moreTagWidth = measureDiv.offsetWidth + HORIZONTAL_GAP;

      // Measure all tags
      const tagWidths = items.map(item => {
        measureDiv.textContent = item;
        return measureDiv.offsetWidth + HORIZONTAL_GAP;
      });

      // Initialize rows
      let rows = [[], []];
      let currentRow = 0;
      let currentRowWidth = 0;
      let visibleCount = 0;
      let needsMoreTag = false;

      // First pass: Fill rows without more tag
      for (let i = 0; i < items.length && currentRow < MAX_ROWS; i++) {
        const tagWidth = tagWidths[i];

        // Check if we need to move to next row
        if (currentRowWidth + tagWidth > containerWidth) {
          if (currentRow + 1 < MAX_ROWS) {
            currentRow++;
            currentRowWidth = 0;
          } else {
            needsMoreTag = true;
            break;
          }
        }

        // Add tag to current row if it fits
        if (currentRowWidth + tagWidth <= containerWidth) {
          rows[currentRow].push(i);
          currentRowWidth += tagWidth;
          visibleCount++;
        }
      }

      // If we have hidden tags, make room for more tag
      if (needsMoreTag || visibleCount < items.length) {
        // Handle last row
        let lastRow = rows[rows.length - 1];
        let lastRowWidth = lastRow.reduce((sum, tagIndex) => sum + tagWidths[tagIndex], 0);

        // Remove tags from the end until more tag fits
        while (lastRowWidth + moreTagWidth > containerWidth && lastRow.length > 0) {
          lastRow.pop();
          visibleCount--;
          lastRowWidth = lastRow.reduce((sum, tagIndex) => sum + tagWidths[tagIndex], 0);
        }
      }

      // Cleanup
      document.body.removeChild(measureDiv);

      // Get final visible tags
      const flattenedIndexes = rows.flat();
      const visibleTagsResult = items.slice(0, flattenedIndexes.length);
      const hiddenCountResult = items.length - flattenedIndexes.length;

      // Update state
      setVisibleTags(visibleTagsResult);
      setHiddenCount(hiddenCountResult);
    };

    // Initial calculation
    const timeoutId = setTimeout(calculateVisibleTags, 0);

    // Recalculate on resize
    const resizeObserver = new ResizeObserver(calculateVisibleTags);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      clearTimeout(timeoutId);
      resizeObserver.disconnect();
    };
  }, [items]);

  return (
    <div 
      className="accepted-items" 
      ref={containerRef}
      style={{
        height: '90px',
      }}
    >
      {visibleTags.map((item, index) => (
        <span
          key={item}
          className="item-tag"
          data-tooltip={item}
        >
          {item}
        </span>
      ))}
      {hiddenCount > 0 && (
        <span 
          className="item-tag more"
          data-tooltip={items.slice(visibleTags.length).join(', ')}
        >
          +{hiddenCount}
        </span>
      )}
    </div>
  );
};

// Card Component
const CharityCardItem = React.memo(({ 
  charity, 
  userCoordinates,
  selectionMode = false,
  selectedCharityId = null,
  onCharitySelect = null 
}) => {
  const [isOpen] = useState(() => utils.checkIfCurrentlyOpen(charity.operatingHours));
  const [distance, setDistance] = useState(null);

  useEffect(() => {
    if (userCoordinates && charity.address.latitude && charity.address.longitude) {
      const dist = utils.calculateDistance(
        userCoordinates.latitude,
        userCoordinates.longitude,
        charity.address.latitude,
        charity.address.longitude
      );
      setDistance(dist ? Math.round(dist * 10) / 10 : null);
    }
  }, [userCoordinates, charity.address]);

  const handleShare = async (e) => {
    e.stopPropagation();
    if (selectionMode) return;
    
    try {
      if (navigator.share) {
        await navigator.share({
          title: charity.name,
          text: `Check out ${charity.name}`,
          url: window.location.href
        });
      } else {
        throw new Error('Share not supported');
      }
    } catch {
      navigator.clipboard.writeText(window.location.href);
      toast.success('Link copied to clipboard!');
    }
  };

  const handleCopy = (e, text, type) => {
    e.stopPropagation();
    if (selectionMode) return;
    navigator.clipboard.writeText(text);
    toast.success(`${type} copied to clipboard!`);
  };

  const handleCardClick = () => {
    if (selectionMode && onCharitySelect) {
      onCharitySelect(charity);
    }
  };

  const isSelected = selectionMode && selectedCharityId === charity.id;

  return (
    <article 
      className={`charity-card ${selectionMode ? 'selectable' : ''} ${isSelected ? 'selected' : ''}`}
      onClick={handleCardClick}
      role={selectionMode ? 'button' : 'article'}
      tabIndex={selectionMode ? 0 : undefined}
      aria-selected={selectionMode ? isSelected : undefined}
    >
      {/* Existing card media section */}
      <div className="card-media">
        <img 
          src={charity.logoUrl} 
          alt={`${charity.name} logo`}
          className="charity-logo"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/placeholder-logo.png';
          }}
        />
        <StatusBadge isOpen={isOpen} />
        {!selectionMode && (
          <div className="card-actions">
            <IconButton
              icon={Icons.FaShareAlt}
              onClick={handleShare}
              label="Share"
              className="share"
            />
          </div>
        )}
      </div>

      <div className="card-content">
        {/* Existing content sections */}
        <h3 className="charity-name">{charity.name}</h3>
        
        <div className="location-info">
          <div className="location-details">
            <Icons.FaMapMarkerAlt />
            <span>{charity.address.city}, {charity.address.state}</span>
            {distance && <span className="distance">{distance} mi</span>}
          </div>
          {!selectionMode && (
            <a 
              href={`https://www.google.com/maps/dir/?api=1&destination=${charity.address.latitude},${charity.address.longitude}`}
              target="_blank"
              rel="noopener noreferrer"
              className="directions-button"
              onClick={(e) => e.stopPropagation()}
            >
              <Icons.FaDirections /> Get Directions
            </a>
          )}
        </div>

        <div className="hours-section">
          <div className="current-hours">
            <Icons.FaClock />
            <span>
              Today: {charity.operatingHours[format(new Date(), 'EEEE').toLowerCase()] || 'Closed'}
            </span>
          </div>
        </div>

        <DynamicItemTags items={charity.acceptedItems} />

        {!selectionMode && (
          <div className="contact-actions">
            <button 
              className="contact-button phone"
              onClick={(e) => handleCopy(e, charity.contactInfo.phone, 'Phone')}
            >
              <Icons.FaPhone /> {charity.contactInfo.phone}
            </button>
            <button 
              className="contact-button email"
              onClick={(e) => handleCopy(e, charity.contactInfo.email, 'Email')}
            >
              <Icons.FaEnvelope />
            </button>
            <a 
              href={charity.contactInfo.website}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-button website"
              onClick={(e) => e.stopPropagation()}
            >
              <Icons.FaGlobe />
            </a>
          </div>
        )}

        <div className="card-buttons">
          {selectionMode && (
            <button 
              className={`select-charity-button ${isSelected ? 'selected' : ''}`}
              onClick={handleCardClick}
            >
              {isSelected ? (
                <>
                  <Icons.FaCheckCircle /> Selected
                </>
              ) : (
                <>
                  <Icons.FaHandPointer /> Select This Charity
                </>
              )}
            </button>
          )}
          
          <button 
            className={`view-details-button ${selectionMode ? 'secondary' : 'primary'}`}
            onClick={(e) => {
              e.stopPropagation();
              // Handle view details action
            }}
          >
            View Full Details <Icons.FaExternalLinkAlt />
          </button>
        </div>
      </div>
    </article>
  );
});

CharityCardItem.displayName = 'CharityCardItem';

// Filters Panel Component
const FiltersPanel = React.memo(({ 
  filters, 
  setFilters, 
  availableStates, 
  availableCities, 
  availableItems,
  isLoadingLocation,
  onZipcodeSearch,
  userCoordinates 
}) => {
  const [localZipcode, setLocalZipcode] = useState(filters.location.zipcode);
  
  const handleZipcodeSubmit = (e) => {
    e.preventDefault();
    if (localZipcode.length === 5) {
      onZipcodeSearch(localZipcode);
    }
  };

  const handleUseCurrentLocation = () => {
    if (userCoordinates) {
      setFilters(prev => ({
        ...prev,
        location: {
          ...prev.location,
          coordinates: userCoordinates
        }
      }));
    }
  };

  return (
    <div className="filters-panel">
      <div className="search-section">
        <div className="search-box">
          <Icons.FaSearch />
          <input
            type="text"
            placeholder="Search charities..."
            value={filters.searchTerm}
            onChange={(e) => setFilters(prev => ({...prev, searchTerm: e.target.value}))}
            className="search-input"
          />
        </div>
      </div>

      <div className="filter-section location-filters">
        <h3><Icons.FaMapPin /> Location</h3>
        
        <div className="location-inputs">
          <div className="zipcode-search">
            <form onSubmit={handleZipcodeSubmit}>
              <div className="zipcode-input-group">
                <input
                  type="text"
                  placeholder="Enter ZIP code"
                  value={localZipcode}
                  onChange={(e) => setLocalZipcode(e.target.value.slice(0, 5))}
                  pattern="[0-9]{5}"
                  maxLength="5"
                  className="zipcode-input"
                />
                <select
                  value={filters.location.radius}
                  onChange={(e) => setFilters(prev => ({
                    ...prev,
                    location: {...prev.location, radius: Number(e.target.value)}
                  }))}
                  className="radius-select"
                >
                  {RADIUS_OPTIONS.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <button 
                type="submit" 
                className="search-location-button"
                disabled={localZipcode.length !== 5 || isLoadingLocation}
              >
                {isLoadingLocation ? <LoadingSpinner /> : 'Search'}
              </button>
            </form>
          </div>

          <button
            type="button"
            onClick={handleUseCurrentLocation}
            className="current-location-button"
            disabled={isLoadingLocation}
          >
            <Icons.FaLocationArrow /> Use Current Location
          </button>

          <div className="state-city-filters">
            <select
              value={filters.location.state}
              onChange={(e) => setFilters(prev => ({
                ...prev,
                location: {...prev.location, state: e.target.value, city: ''}
              }))}
              className="state-select"
            >
              <option value="">All States</option>
              {availableStates.map(state => (
                <option key={state} value={state}>{state}</option>
              ))}
            </select>

            {filters.location.state && (
              <select
                value={filters.location.city}
                onChange={(e) => setFilters(prev => ({
                  ...prev,
                  location: {...prev.location, city: e.target.value}
                }))}
                className="city-select"
              >
                <option value="">All Cities</option>
                {availableCities.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
            )}
          </div>
        </div>
      </div>

      <div className="filter-section hours-filters">
        <h3><Icons.FaRegClock /> Hours</h3>
        <div className="hours-options">
          {['currentlyOpen', 'weekends', 'evenings'].map(filter => (
            <label key={filter} className="switch-label">
              <div className="switch">
                <input
                  type="checkbox"
                  checked={filters.hours[filter]}
                  onChange={(e) => setFilters(prev => ({
                    ...prev,
                    hours: {...prev.hours, [filter]: e.target.checked}
                  }))}
                />
                <span className="slider"></span>
              </div>
              {filter.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
            </label>
          ))}
        </div>
      </div>

      <div className="filter-section items-filters">
        <h3><Icons.FaBoxOpen /> Accepted Items</h3>
        <div className="items-grid">
          {availableItems.map(item => (
            <label key={item} className="checkbox-label">
              <input
                type="checkbox"
                checked={filters.items.includes(item)}
                onChange={(e) => {
                  const newItems = e.target.checked
                    ? [...filters.items, item]
                    : filters.items.filter(i => i !== item);
                  setFilters(prev => ({...prev, items: newItems}));
                }}
              />
              <span className="custom-checkbox"></span>
              {item}
            </label>
          ))}
        </div>
      </div>

      <div className="filter-actions">
        <button 
          className="clear-filters-button"
          onClick={() => setFilters({...INITIAL_FILTERS})}
        >
          <Icons.FaTimes /> Clear All Filters
        </button>
      </div>
    </div>
  );
});

FiltersPanel.displayName = 'FiltersPanel';

// Main Component
const CharityCards = ({ 
  charities,
  selectionMode = false,
  selectedCharityId = null,
  onCharitySelect = null
}) => {
  const [filters, setFilters] = useState(INITIAL_FILTERS);
  const [availableStates, setAvailableStates] = useState([]);
  const [availableCities, setAvailableCities] = useState([]);
  const [availableItems, setAvailableItems] = useState([]);
  const { coordinates: userCoordinates, isLoading: isLoadingLocation, error: locationError } = useGeolocation();

  // Initialize filter options
  useEffect(() => {
    const states = [...new Set(charities.map(c => c.address.state))].sort();
    const items = [...new Set(charities.flatMap(c => c.acceptedItems))].sort();
    
    setAvailableStates(states);
    setAvailableItems(items);
  }, [charities]);

  // Update available cities when state changes
  useEffect(() => {
    if (filters.location.state) {
      const cities = [...new Set(
        charities
          .filter(c => c.address.state === filters.location.state)
          .map(c => c.address.city)
      )].sort();
      setAvailableCities(cities);
    } else {
      setAvailableCities([]);
    }
  }, [filters.location.state, charities]);

  // Fetch coordinates for entered zipcode
  const fetchZipcodeCoordinates = async (zipcode) => {
    try {
      const response = await fetch(`/api/geocode/zipcode/${zipcode}`);
      if (!response.ok) throw new Error('Failed to fetch coordinates');
      
      const data = await response.json();
      if (data.coordinates) {
        setFilters(prev => ({
          ...prev,
          location: {
            ...prev.location,
            coordinates: data.coordinates
          }
        }));
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      toast.error('Error fetching location data');
    }
  };

  // Check if charity is within radius
  const isWithinRadius = useCallback((charity, coordinates, radius) => {
    if (!coordinates || !charity.address.latitude || !charity.address.longitude) {
      return true;
    }

    const distance = utils.calculateDistance(
      coordinates.latitude,
      coordinates.longitude,
      charity.address.latitude,
      charity.address.longitude
    );

    return distance <= radius;
  }, []);

  // Filter and sort charities
  const filteredCharities = useMemo(() => {
    return charities.filter(charity => {
      // Location filters
      if (filters.location.state && charity.address.state !== filters.location.state) return false;
      if (filters.location.city && charity.address.city !== filters.location.city) return false;
      if (filters.location.coordinates && !isWithinRadius(
        charity, 
        filters.location.coordinates, 
        filters.location.radius
      )) return false;
  
      // Hours filters
      if (filters.hours.currentlyOpen && !utils.checkIfCurrentlyOpen(charity.operatingHours)) {
        return false;
      }
      if (filters.hours.weekends && !utils.hasWeekendHours(charity.operatingHours)) {
        return false;
      }
      if (filters.hours.evenings && !utils.hasEveningHours(charity.operatingHours)) {
        return false;
      }

      // Items filter
      if (filters.items.length > 0 && !filters.items.some(item => 
        charity.acceptedItems.map(i => i.toLowerCase()).includes(item.toLowerCase())
      )) return false;

      // Search term
      if (filters.searchTerm) {
        const searchLower = filters.searchTerm.toLowerCase();
        return (
          charity.name.toLowerCase().includes(searchLower) ||
          charity.description.toLowerCase().includes(searchLower) ||
          charity.acceptedItems.some(item => item.toLowerCase().includes(searchLower))
        );
      }

      return true;
    }).sort((a, b) => {
      switch (filters.sortBy) {
        case 'name':
          return a.name.localeCompare(b.name);
        case 'recent':
          return new Date(b.createdAt) - new Date(a.createdAt);
        case 'distance':
          if (!filters.location.coordinates) return 0;
          const distA = utils.calculateDistance(
            filters.location.coordinates.latitude,
            filters.location.coordinates.longitude,
            a.address.latitude,
            a.address.longitude
          ) || 0;
          const distB = utils.calculateDistance(
            filters.location.coordinates.latitude,
            filters.location.coordinates.longitude,
            b.address.latitude,
            b.address.longitude
          ) || 0;
          return distA - distB;
        default:
          return 0;
      }
    });
  }, [charities, filters, isWithinRadius]);

  // Show error if geolocation fails
  useEffect(() => {
    if (locationError) {
      toast.error('Unable to access location services');
    }
  }, [locationError]);

  return (
    <div className="charity-cards-container">
      <div className={`filters-section ${filters.showFilters ? 'show' : 'hide'}`}>
        <FiltersPanel 
          filters={filters}
          setFilters={setFilters}
          availableStates={availableStates}
          availableCities={availableCities}
          availableItems={availableItems}
          isLoadingLocation={isLoadingLocation}
          onZipcodeSearch={fetchZipcodeCoordinates}
          userCoordinates={userCoordinates}
        />
      </div>

      <div className="results-section">
        <div className="results-header">
          <div className="results-summary">
            <button 
              className="toggle-filters-button"
              onClick={() => setFilters(prev => ({
                ...prev, 
                showFilters: !prev.showFilters
              }))}
            >
              <Icons.FaFilter /> Filters
            </button>
            <h2>
              {selectionMode ? 
                'Select a Charity' : 
                `Found ${filteredCharities.length} Charities`
              }
            </h2>
          </div>

          <div className="sorting-controls">
            <select
              value={filters.sortBy}
              onChange={(e) => setFilters(prev => ({
                ...prev, 
                sortBy: e.target.value
              }))}
              className="sort-select"
            >
              {SORT_OPTIONS.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className={`cards-grid ${selectionMode ? 'selection-mode' : ''}`}>
          {filteredCharities.map(charity => (
            <CharityCardItem
              key={charity.id}
              charity={charity}
              userCoordinates={filters.location.coordinates || userCoordinates}
              selectionMode={selectionMode}
              selectedCharityId={selectedCharityId}
              onCharitySelect={onCharitySelect}
            />
          ))}
          {filteredCharities.length === 0 && (
            <div className="no-results">
              <Icons.FaInfoCircle />
              <h3>No charities found</h3>
              <p>Try adjusting your filters or search terms</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CharityCards;