// src/services/companyService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { userService } from '../services/userService';

const COLLECTION_NAME = 'companies';

export const companyService = {
  async create(companyData) {
    try {
      const newCompanyRef = doc(collection(db, COLLECTION_NAME));
      await setDoc(newCompanyRef, companyData);
      
      // Assign the new company to all System Admins
      const systemAdmins = await userService.getUsersByRole('SystemAdmin');
      for (const admin of systemAdmins) {
        await userService.update(admin.id, {
          companies: [...(admin.companies || []), newCompanyRef.id]
        });
      }
  
      return newCompanyRef.id;
    } catch (error) {
      console.error('Error creating company:', error);
      throw error;
    }
  },

  async getById(companyId) {
    const companyDoc = await getDoc(doc(db, COLLECTION_NAME, companyId));
    return companyDoc.exists() ? { id: companyDoc.id, ...companyDoc.data() } : null;
  },

  async getAll() {
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async update(companyId, updateData) {
    await updateDoc(doc(db, COLLECTION_NAME, companyId), updateData);
  },

  async delete(companyId) {
    await deleteDoc(doc(db, COLLECTION_NAME, companyId));
  }
};