// src/components/NewCompanySignUp.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { companyService } from '../services/companyService';
import { userService } from '../services/userService';
import { authService } from '../services/authService';
import '../styles/NewCompanySignUp.css';

function NewCompanySignUp() {
  const [formData, setFormData] = useState({
    // Company data
    companyName: '',
    companyContactName: '',
    companyContactEmail: '',
    companyContactPhone: '',

    // User data
    userName: '',
    userEmail: '',
    userPhone: '',
    userPassword: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Create Company
      const companyId = await companyService.create({
        name: formData.companyName,
        contact: {
          name: formData.companyContactName,
          email: formData.companyContactEmail,
          phone: formData.companyContactPhone
        }
      });
  
      // Register user with Firebase Authentication
      const firebaseUser = await authService.register(formData.userEmail, formData.userPassword);
  
      // Create User in Firestore
      const userData = {
        name: formData.userName,
        email: formData.userEmail,
        phone: formData.userPhone,
        role: "Admin",
        company: companyId,
      };
      await userService.create(firebaseUser.uid, userData);
  
      // Set user session
      authService.setUserSession({ ...userData, id: firebaseUser.uid });
  
      // Redirect to My Team page
      navigate('/my-team');
    } catch (error) {
      console.error('Error during sign up:', error);
      alert('An error occurred during sign up: ' + error.message);
    }
  };

  return (
    <div className="signup-container">
      <div className="signup-card">
        <div className="signup-image"></div>
        <div className="signup-form">
          <h2 className="signup-title">Join Donating Simplified</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-section">
              <h3 className="section-title">Company Information</h3>
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleChange}
                  placeholder="Company Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="companyContactName"
                  value={formData.companyContactName}
                  onChange={handleChange}
                  placeholder="Contact Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="email"
                  name="companyContactEmail"
                  value={formData.companyContactEmail}
                  onChange={handleChange}
                  placeholder="Contact Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="tel"
                  name="companyContactPhone"
                  value={formData.companyContactPhone}
                  onChange={handleChange}
                  placeholder="Contact Phone"
                  required
                />
              </div>
            </div>

            <div className="form-section">
              <h3 className="section-title">Admin User</h3>
              <div className="form-group">
                <input
                  className="form-input"
                  type="text"
                  name="userName"
                  value={formData.userName}
                  onChange={handleChange}
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="email"
                  name="userEmail"
                  value={formData.userEmail}
                  onChange={handleChange}
                  placeholder="Your Email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="tel"
                  name="userPhone"
                  value={formData.userPhone}
                  onChange={handleChange}
                  placeholder="Your Phone Number"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  className="form-input"
                  type="password"
                  name="userPassword"
                  value={formData.userPassword}
                  onChange={handleChange}
                  placeholder="Choose a Password"
                  required
                />
              </div>
            </div>

            <button className="submit-button" type="submit">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewCompanySignUp;