// src/components/ManageLogistics.js


import React, { useReducer, useCallback, useMemo, useEffect, useState } from 'react';
import {
  FaSearch,
  FaCalendarAlt,
  FaClock,
  FaTruck,
  FaCheckCircle,
  FaExclamationTriangle,
  FaWarehouse,
  FaFilter,
  FaDownload,
  FaTimesCircle,
  FaCircle,
  FaBuilding
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { donationService } from '../services/donationService';
import { distributionCenterService } from '../services/distributionCenterService';
import { logisticsService } from '../services/logisticsService';
import { approvalRequestService } from '../services/approvalRequestService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { companyService } from '../services/companyService';
import { charityService } from '../services/charityService';
import { quoteService } from '../services/quoteService';
import { completedDonationsService } from '../services/completedDonationsService';
import { messageService } from '../services/messageService';
import '../styles/ManageLogistics.css';


const initialState = {
  quotes: [],
  logisticsTasks: [],
  user: null,
  userRole: null,
  loading: true,
  error: null,
  filters: {
    status: 'all',
    distributionCenter: 'all',
    company: 'all',
    dateRange: { start: null, end: null },
    searchTerm: '',
  },
  distributionCenters: [],
  companies: [],
};


function reducer(state, action) {
  switch (action.type) {
    case 'SET_DATA':
      return { ...state, ...action.payload, loading: false };
    case 'SET_ERROR':
      return { ...state, error: action.payload, loading: false };
    case 'SET_FILTER':
      return { ...state, filters: { ...state.filters, [action.payload.name]: action.payload.value } };
    case 'RESET_FILTERS':
      return { ...state, filters: initialState.filters };
    default:
      return state;
  }
}


const statusOptions = [
  { value: 'notScheduled', icon: FaCircle, color: '#6c757d', label: 'Not Scheduled' },
  { value: 'pendingApproval', icon: FaExclamationTriangle, color: '#FFA500', label: 'Pending Approval' },
  { value: 'scheduled', icon: FaCalendarAlt, color: '#3498DB', label: 'Scheduled' },
  { value: 'inProgress', icon: FaTruck, color: '#28A745', label: 'In Progress' },
  { value: 'completed', icon: FaCheckCircle, color: '#28A745', label: 'Completed' },
  { value: 'rejected', icon: FaTimesCircle, color: '#E74C3C', label: 'Rejected' },
];


const processDonationCompletion = async (task, acceptedDate, taxReceiptUrl) => {
  // Get the quote for this specific item
  const quote = await quoteService.getById(task.quoteId);
  if (!quote) {
    throw new Error(`Quote not found: ${task.quoteId}`);
  }

  // Get the donation to access the specific item
  const donation = await donationService.getById(task.donationId);
  if (!donation) {
    throw new Error(`Donation not found: ${task.donationId}`);
  }

  // Find the specific item this task belongs to using consistent itemID property
  const currentItem = donation.items.find(item => item.itemID === task.itemId);

  if (!currentItem) {
    throw new Error(`Item not found in donation: ${task.itemId}`);
  }

  // Get all tasks for this specific item's quote
  const allItemTasks = await logisticsService.getTasks({ 
    quoteId: quote.id,
    itemId: currentItem.itemID
  });
  
  // Create a map of completed tasks for this item
  const completedTasksMap = {};
  
  // Add all previously completed tasks
  allItemTasks
    .filter(t => t.status === 'completed' && t.id !== task.id)
    .forEach(t => {
      const key = `${currentItem.itemID}_${t.charityId}`;
      completedTasksMap[key] = t;
    });
    
  // Add the current task being completed
  const currentTaskKey = `${currentItem.itemID}_${task.charityId}`;
  completedTasksMap[currentTaskKey] = {
    ...task,
    status: 'completed',
    taxReceiptUrl
  };

  // Check if all pallet groups for this item are completed
  let allItemPalletGroupsComplete = true;
  const expectedPalletGroups = currentItem.assignedCharities.length;
  const completedPalletGroups = Object.keys(completedTasksMap).length;

  if (completedPalletGroups < expectedPalletGroups) {
    allItemPalletGroupsComplete = false;
  } else {
    // Verify each charity has a completed task with a tax receipt
    currentItem.assignedCharities.forEach(charity => {
      const key = `${currentItem.itemID}_${charity.charityId}`;
      if (!completedTasksMap[key]?.taxReceiptUrl) {
        allItemPalletGroupsComplete = false;
      }
    });
  }

  if (allItemPalletGroupsComplete) {
    // Process completed donation data for this item
    const processedData = {
      fmvAssessmentUrls: {},
      taxReceiptUrls: {},
      palletGroups: [],
      totalCostBasis: Number(currentItem.costBasis) || 0,
      totalWeight: Number(currentItem.totalWeight) || 0,
      quantity: Number(currentItem.quantity) || 0, // Add quantity from currentItem
      unitOfMeasure: currentItem.unitOfMeasure || 'Units' // Add unit of measure
    };

    // Ensure FMV assessment URL exists and is properly formatted
    if (!currentItem.fmvAssessmentUrl?.trim()) {
      throw new Error(`Missing FMV assessment URL for item ${currentItem.itemID}`);
    }

    // Use itemID as the key
    processedData.fmvAssessmentUrls[currentItem.itemID] = currentItem.fmvAssessmentUrl.trim();

    // Process each charity's pallet group for this item
    currentItem.assignedCharities.forEach((charity, charityIndex) => {
      const key = `${currentItem.itemID}_${charity.charityId}`;
      const completedTask = completedTasksMap[key];

      if (!completedTask?.taxReceiptUrl) {
        throw new Error(
          `Missing tax receipt for item ${currentItem.itemID}, charity ${charity.charityName}`
        );
      }

      // Add pallet group
      processedData.palletGroups.push({
        itemId: currentItem.itemID,
        charityId: charity.charityId,
        charityName: charity.charityName,
        palletGroup: charityIndex + 1,
        palletQuantity: charity.palletCount
      });

      // Use itemID and charityIndex for tax receipt key
      const receiptKey = `${currentItem.itemID}_${charityIndex + 1}`;
      processedData.taxReceiptUrls[receiptKey] = completedTask.taxReceiptUrl.trim();
    });

    // Create completed donation record for this item
    const completedDonation = await completedDonationsService.createCompletedDonation(
      quote.id,
      processedData.taxReceiptUrls,
      quote.pdfUrl,
      processedData.palletGroups,
      task.distributionCenterId,
      processedData.fmvAssessmentUrls,
      currentItem.description || '',
      processedData.totalCostBasis,
      processedData.totalWeight,
      processedData.quantity, // Add quantity as an argument
      processedData.unitOfMeasure // Add unit of measure as an argument
    );

    // Update quote status to completed
    await quoteService.completeQuote(quote.id);

    // Update the item's status within the donation document
    const updatedItems = donation.items.map(item => {
      if (item.itemID === currentItem.itemID) {
        return { ...item, status: 'quote completed' };
      }
      return item;
    });

    await donationService.update(task.donationId, { items: updatedItems });

    return {
      status: 'item_completed',
      completedDonation,
      itemId: currentItem.itemID
    };
  }

  return {
    status: 'partially_completed',
    remainingCount: expectedPalletGroups - completedPalletGroups,
    itemId: currentItem.itemID
  };
};


const ManageLogistics = () => {
  const [state, dispatch] = useReducer(reducer, initialState);


  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');
 
      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');
 
      const userRole = await userService.getUserRole(currentUser.id);
      if (userRole !== 'SystemAdmin') {
        throw new Error('Access denied. Only System Admins can manage logistics.');
      }
 
      console.log('Fetching initial data...');
      const [companies, allDCs, approvedQuotes, tasks] = await Promise.all([
        companyService.getAll(),
        distributionCenterService.getAll(),
        quoteService.getApprovedQuotes(),
        logisticsService.getTasks()
      ]);


      const enrichedQuotes = await Promise.all(approvedQuotes.map(async (quote) => {
        try {
          const donation = await donationService.getById(quote.donationId);
          if (!donation) return null;
     
          const distributionCenter = allDCs.find(dc => dc.id === quote.distributionCenterId);
          if (!distributionCenter) return null;
     
          const company = companies.find(c => c.id === quote.companyId);
          if (!company) return null;
 
          // Get assigned charities from donation items
          const matchingItem = donation.items?.find(item =>
            item.itemID === quote.itemId ||
            item.itemId === quote.itemId ||
            item.id === quote.itemId
          );


          if (!matchingItem?.assignedCharities?.length) return null;


          return {
            ...quote,
            donation,
            distributionCenter,
            company,
            description: quote.description || matchingItem.description || 'No description',
            palletGroups: matchingItem.assignedCharities.map(charity => ({
              itemId: matchingItem.itemID,
              charityId: charity.charityId,
              charityName: charity.charityName,
              palletCount: charity.palletCount
            }))
          };
        } catch (error) {
          console.error(`Error processing quote ${quote.id}:`, error);
          return null;
        }
      }));
 
      const validQuotes = enrichedQuotes.filter(quote => quote !== null);
 
      dispatch({
        type: 'SET_DATA',
        payload: {
          user: userData,
          userRole,
          distributionCenters: allDCs,
          companies,
          quotes: validQuotes,
          logisticsTasks: tasks
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to load logistics data. Please refresh the page.');
    }
  }, []);


  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const handleSchedule = useCallback(async (quote, palletGroup, pickupDateTime, deliveryDate) => {
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
 
      const currentUser = authService.getUserSession();
      if (!currentUser?.id) {
        throw new Error('No authenticated user found');
      }
 
      const existingTask = state.logisticsTasks.find(t =>
        t.quoteId === quote.id &&
        t.charityId === palletGroup.charityId
      );
 
      const taskData = {
        quoteId: quote.id,
        donationId: quote.donationId,
        itemId: palletGroup.itemId,
        distributionCenterId: quote.distributionCenterId,
        charityId: palletGroup.charityId,
        charityName: palletGroup.charityName,
        palletGroup: palletGroup.palletGroup || 1,
        palletQuantity: palletGroup.palletCount,
        pickupDateTime: pickupDateTime,
        deliveryDate: deliveryDate,
        status: 'pendingApproval',
        itemDescription: quote.description,
        shippingAddress: quote.distributionCenter?.address || '',
        requestedByUserId: currentUser.id,
        lastUpdatedBy: currentUser.id,
        lastUpdatedAt: new Date().toISOString()
      };
     
      let newTask;
      if (existingTask) {
        newTask = await logisticsService.updateTask(existingTask.id, {
          ...taskData,
          createdBy: existingTask.createdBy || currentUser.id,
          createdAt: existingTask.createdAt
        });
        toast.success('Task rescheduled successfully');
      } else {
        newTask = await logisticsService.createTask({
          ...taskData,
          createdBy: currentUser.id,
          createdAt: new Date().toISOString()
        });
        toast.success('New task scheduled successfully');
      }
 
      await approvalRequestService.createRequest({
        type: 'pickupDateApproval',
        taskId: newTask.id,
        distributionCenterId: quote.distributionCenterId,
        pickupDateTime,
        deliveryDate,
        requestedByUserId: currentUser.id,
        createdAt: new Date().toISOString(),
        status: 'pending'
      });
 
      try {
        const creator = await userService.getById(quote.requestorId);
        if (creator?.phone) {
          await messageService.sendPickupApprovalRequestNotification(
            creator.phone,
            {
              itemDescription: quote.description,
              distributionCenterName: quote.distributionCenter.name,
              pickupDateTime,
              deliveryDate,
              charityName: palletGroup.charityName,
              palletQuantity: palletGroup.palletCount
            }
          );
        }
      } catch (messageError) {
        console.error('Error sending pickup approval notification:', messageError);
        toast.warn('Task scheduled but notification could not be sent');
      }
 
      await fetchData();
    } catch (error) {
      console.error('Error scheduling logistics task:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error('Failed to schedule task. Please try again.');
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  }, [fetchData, state.logisticsTasks]);


  const handleCompleteTask = async (task, acceptedDate, taxReceiptFile) => {
    let taxReceiptUrl;
    
    try {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: true } });
      console.log('Starting task completion process for task:', task.id);
  
      // Step 1: Upload tax receipt
      try {
        taxReceiptUrl = await logisticsService.uploadTaxReceipt(taxReceiptFile, task.id);
        console.log('Tax receipt uploaded:', taxReceiptUrl);
      } catch (uploadError) {
        console.error('Failed to upload tax receipt:', uploadError);
        throw new Error(`Failed to upload tax receipt: ${uploadError.message}`);
      }
  
      // Step 2: Update the current task status
      try {
        await logisticsService.updateTask(task.id, {
          status: 'completed',
          deliveryDate: acceptedDate,
          acceptedByCharityDate: acceptedDate,
          taxReceiptUrl,
          lastUpdatedAt: new Date().toISOString()
        });
        console.log('Task status updated to completed');
      } catch (updateError) {
        console.error('Failed to update task status:', updateError);
        throw new Error(`Failed to update task status: ${updateError.message}`);
      }
  
      // Step 3: Process the item completion
      try {
        const result = await processDonationCompletion(task, acceptedDate, taxReceiptUrl);
        console.log('Item completion processed:', result);
  
        if (result.status === 'item_completed') {
          // Try to send completion notification for this item
          try {
            const [distributionCenter, creator] = await Promise.all([
              distributionCenterService.getById(task.distributionCenterId),
              quoteService.getById(task.quoteId).then(quote =>
                quote?.requestorId ? userService.getById(quote.requestorId) : null
              )
            ]);
  
            if (creator?.phone && distributionCenter) {
              const notificationData = {
                itemDescription: task.itemDescription || 'Item',
                distributionCenterName: distributionCenter.name,
                fmvAmount: Number(result.completedDonation.fairMarketValue) || 0,
                totalPallets: result.completedDonation.palletGroups.reduce(
                  (sum, group) => sum + Number(group.palletQuantity), 0
                ),
                completionDate: new Date().toISOString(),
                charities: result.completedDonation.palletGroups.map(group => ({
                  name: group.charityName,
                  palletCount: Number(group.palletQuantity)
                }))
              };
  
              await messageService.sendDonationCompletionNotification(
                creator.phone,
                notificationData
              );
              console.log('Item completion notification sent successfully');
            }
          } catch (notificationError) {
            console.error('Error sending completion notification:', notificationError);
            toast.warn('Item completed but notification could not be sent');
          }
  
          toast.success('🎉 Item fully completed!');
        } else {
          // Some pallet groups still remaining
          const message = result.remainingCount === 1
            ? '1 pallet group remaining for this item'
            : `${result.remainingCount} pallet groups remaining for this item`;
          toast.success(`Task completed successfully! ${message}`);
        }
  
        await fetchData();
      } catch (processingError) {
        console.error('Error processing item completion:', processingError);
        
        // Attempt to revert task status
        try {
          await logisticsService.updateTask(task.id, {
            status: 'inProgress',
            deliveryDate: null,
            acceptedByCharityDate: null,
            taxReceiptUrl: null,
            lastUpdatedAt: new Date().toISOString()
          });
          console.log('Task status reverted due to error');
        } catch (revertError) {
          console.error('Failed to revert task status:', revertError);
        }
  
        throw new Error(`Failed to process item completion: ${processingError.message}`);
      }
    } catch (error) {
      console.error('Task completion failed:', error);
      dispatch({ type: 'SET_ERROR', payload: error.message });
      toast.error(error.message);
    } finally {
      dispatch({ type: 'SET_FILTER', payload: { name: 'loading', value: false } });
    }
  };


  const filteredQuotes = useMemo(() => {
    return state.quotes.filter(quote => {
      const searchLower = state.filters.searchTerm.toLowerCase();
      const matchesSearch =
        quote.id.toLowerCase().includes(searchLower) ||
        quote.description?.toLowerCase().includes(searchLower) ||
        quote.distributionCenter?.name.toLowerCase().includes(searchLower) ||
        quote.company?.name.toLowerCase().includes(searchLower) ||
        quote.palletGroups.some(group =>
          group.charityName.toLowerCase().includes(searchLower) ||
          group.palletCount.toString().includes(searchLower)
        );
     
      const matchesDistributionCenter = state.filters.distributionCenter === 'all' ||
        quote.distributionCenterId === state.filters.distributionCenter;


      const matchesCompany = state.filters.company === 'all' ||
        quote.companyId === state.filters.company;
 
      const matchesStatus = state.filters.status === 'all' ||
        quote.palletGroups.some(group => {
          const task = state.logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === group.charityId
          );
          if (state.filters.status === 'notScheduled') {
            return !task;
          }
          return task && task.status === state.filters.status;
        });
 
      const matchesDateRange =
        (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
        quote.palletGroups.some(group => {
          const task = state.logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === group.charityId
          );
          return task &&
            new Date(task.pickupDateTime) >= new Date(state.filters.dateRange.start) &&
            new Date(task.deliveryDate) <= new Date(state.filters.dateRange.end);
        });
 
      return matchesSearch && matchesDistributionCenter && matchesCompany && matchesStatus && matchesDateRange;
    });
  }, [state.quotes, state.logisticsTasks, state.filters]);


  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;
  if (state.userRole !== 'SystemAdmin') return <ErrorMessage message="Access denied. Only System Admins can manage logistics." />;


  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="manage-logistics-container">
          <div className="logistics-content">
            <h1><FaTruck /> Manage Logistics</h1>
            <FilterBar
              filters={state.filters}
              distributionCenters={state.distributionCenters}
              companies={state.companies}
              dispatch={dispatch}
              statusOptions={statusOptions}
            />
            <LogisticsTable
              quotes={filteredQuotes}
              logisticsTasks={state.logisticsTasks}
              onSchedule={handleSchedule}
              onCompleteTask={handleCompleteTask}
              statusOptions={statusOptions}
            />
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};


const FilterBar = React.memo(({ filters, distributionCenters, companies, dispatch, statusOptions }) => (
  <div className="filters">
    <div className="filter-group">
      <FaSearch className="icon" />
      <input
        type="text"
        placeholder="Search by quote ID, item, charity, or pallet quantity"
        value={filters.searchTerm}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'searchTerm', value: e.target.value } })}
      />
    </div>
    <div className="filter-group">
      <FaBuilding className="icon" />
      <select
        value={filters.company}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'company', value: e.target.value } })}
      >
        <option value="all">All Companies</option>
        {companies.map(company => (
          <option key={company.id} value={company.id}>{company.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaWarehouse className="icon" />
      <select
        value={filters.distributionCenter}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'distributionCenter', value: e.target.value } })}
      >
        <option value="all">All Distribution Centers</option>
        {distributionCenters.map(dc => (
          <option key={dc.id} value={dc.id}>{dc.name}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaFilter className="icon" />
      <select
        value={filters.status}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'status', value: e.target.value } })}
      >
        <option value="all">All Statuses</option>
        {statusOptions.map(option => (
          <option key={option.value} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
    <div className="filter-group">
      <FaCalendarAlt className="icon" />
      <input
        type="date"
        value={filters.dateRange.start || ''}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, start: e.target.value } } })}
      />
      <span>to</span>
      <input
        type="date"
        value={filters.dateRange.end || ''}
        onChange={(e) => dispatch({ type: 'SET_FILTER', payload: { name: 'dateRange', value: { ...filters.dateRange, end: e.target.value } } })}
      />
    </div>
    <button
      className="btn btn-reset"
      onClick={() => dispatch({ type: 'RESET_FILTERS' })}
    >
      Reset Filters
    </button>
  </div>
));


const StatusIndicator = ({ status, statusOptions }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
 
  return (
    <div
      className={`status-indicator ${status}`}
      data-tooltip-id="tooltip"
      data-tooltip-content={statusOption.label}
    >
      <Icon /> <span>{statusOption.label}</span>
    </div>
  );
};


const LogisticsTable = React.memo(({ quotes, logisticsTasks, onSchedule, onCompleteTask, statusOptions }) => {
  const [expandedQuote, setExpandedQuote] = useState(null);


  const handleExpand = (quoteId) => {
    setExpandedQuote(expandedQuote === quoteId ? null : quoteId);
  };


  return (
    <div className="logistics-table-container">
      <table className="logistics-table">
        <thead>
          <tr>
            <th>Quote ID</th>
            <th>Company</th>
            <th>Item Description</th>
            <th>Distribution Center</th>
            <th>Total Pallets</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {quotes.map(quote => (
            <React.Fragment key={quote.id}>
              <tr className={expandedQuote === quote.id ? 'expanded' : ''}>
                <td>{quote.id}</td>
                <td>{quote.company?.name || 'N/A'}</td>
                <td>{quote.description || 'N/A'}</td>
                <td>{quote.distributionCenter?.name || 'N/A'}</td>
                <td>{quote.palletGroups.reduce((sum, pg) => sum + pg.palletCount, 0)}</td>
                <td>
                  <button className="btn btn-expand" onClick={() => handleExpand(quote.id)}>
                    {expandedQuote === quote.id ? 'Collapse' : 'Expand'}
                  </button>
                </td>
              </tr>
              {expandedQuote === quote.id && (
                <tr>
                  <td colSpan="6">
                    <PalletGroupTable
                      quote={quote}
                      logisticsTasks={logisticsTasks}
                      onSchedule={onSchedule}
                      onCompleteTask={onCompleteTask}
                      statusOptions={statusOptions}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
});


const PalletGroupTable = React.memo(({ quote, logisticsTasks, onSchedule, onCompleteTask, statusOptions }) => {
  const [schedulingGroup, setSchedulingGroup] = useState(null);
  const [completingGroup, setCompletingGroup] = useState(null);
  const [completingTaskId, setCompletingTaskId] = useState(null);


  const handleScheduleClick = (group) => {
    setSchedulingGroup(group);
    setCompletingGroup(null);
  };


  const handleCompleteClick = (group) => {
    setCompletingGroup(group);
    setSchedulingGroup(null);
  };


  const handleScheduleSubmit = (e, group) => {
    e.preventDefault();
    const pickupDate = e.target.pickupDate.value;
    const pickupTime = e.target.pickupTime.value;
    const deliveryDate = e.target.deliveryDate.value;
    const pickupDateTime = `${pickupDate}T${pickupTime}:00`;
    onSchedule(quote, group, pickupDateTime, deliveryDate);
    setSchedulingGroup(null);
  };


  const handleCompleteSubmit = async (e, group, task) => {
    e.preventDefault();
    setCompletingTaskId(task.id);
    try {
      const acceptedDate = e.target.acceptedDate.value;
      const taxReceiptFile = e.target.taxReceipt.files[0];
     
      if (!taxReceiptFile) {
        throw new Error('Please select a tax receipt file');
      }


      if (!acceptedDate) {
        throw new Error('Please select the accepted date');
      }


      await onCompleteTask(task, acceptedDate, taxReceiptFile);
      setCompletingGroup(null);
    } catch (error) {
      toast.error(error.message);
    } finally {
      setCompletingTaskId(null);
    }
  };


  return (
    <table className="pallet-group-table">
      <thead>
        <tr>
          <th>Charity</th>
          <th>Pallet Count</th>
          <th>Status</th>
          <th>Pickup Date/Time</th>
          <th>Delivery Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {quote.palletGroups.map(group => {
          const task = logisticsTasks.find(t =>
            t.quoteId === quote.id &&
            t.charityId === group.charityId
          );
          const isCompleting = task && completingTaskId === task.id;


          return (
            <tr key={group.charityId}>
              <td>{group.charityName}</td>
              <td>{group.palletCount}</td>
              <td>
                <StatusIndicator
                  status={task ? task.status : 'notScheduled'}
                  statusOptions={statusOptions}
                />
              </td>
              <td>{task ? new Date(task.pickupDateTime).toLocaleString() : '-'}</td>
              <td>{task ? new Date(task.deliveryDate).toLocaleDateString() : '-'}</td>
              <td>
                <div className="action-buttons">
                  {(!task || task.status === 'rejected') && (
                    <button
                      className="btn btn-schedule"
                      onClick={() => handleScheduleClick(group)}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={task ? "Reschedule rejected task" : "Schedule new task"}
                    >
                      {task ? 'Reschedule' : 'Schedule'}
                    </button>
                  )}
                  {task && (task.status === 'scheduled' || task.status === 'inProgress') && (
                    <button
                      className="btn btn-complete"
                      onClick={() => handleCompleteClick(group)}
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Complete task"
                      disabled={isCompleting}
                    >
                      {isCompleting ? (
                        <span className="loading-button">
                          <div className="spinner"></div>
                          Completing...
                        </span>
                      ) : (
                        'Complete'
                      )}
                    </button>
                  )}
                  {task && task.bolPdfUrl && (
                    <a
                      href={task.bolPdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-download"
                      data-tooltip-id="tooltip"
                      data-tooltip-content="Download Bill of Lading"
                    >
                      <FaDownload /> BOL
                    </a>
                  )}
                </div>
                {schedulingGroup && schedulingGroup.charityId === group.charityId && (
                  <form onSubmit={(e) => handleScheduleSubmit(e, group)} className="scheduling-form">
                    <div className="form-group">
                      <label htmlFor="pickupDate">Pickup Date:</label>
                      <input type="date" id="pickupDate" name="pickupDate" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="pickupTime">Pickup Time:</label>
                      <input type="time" id="pickupTime" name="pickupTime" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="deliveryDate">Delivery Date:</label>
                      <input type="date" id="deliveryDate" name="deliveryDate" required />
                    </div>
                    <div className="form-actions">
                      <button type="submit" className="btn btn-confirm-schedule">
                        Confirm
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel-schedule"
                        onClick={() => setSchedulingGroup(null)}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
                {completingGroup && completingGroup.charityId === group.charityId && (
                  <form onSubmit={(e) => handleCompleteSubmit(e, group, task)} className="completing-form">
                    <div className="form-group">
                      <label htmlFor="acceptedDate">Accepted by Charity Date:</label>
                      <input
                        type="date"
                        id="acceptedDate"
                        name="acceptedDate"
                        required
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="taxReceipt">Tax Receipt PDF:</label>
                      <input
                        type="file"
                        id="taxReceipt"
                        name="taxReceipt"
                        accept=".pdf"
                        required
                        disabled={isCompleting}
                      />
                    </div>
                    <div className="form-actions">
                      <button
                        type="submit"
                        className="btn btn-confirm-complete"
                        disabled={isCompleting}
                      >
                        {isCompleting ? (
                          <span className="loading-button">
                            <div className="spinner"></div>
                            Processing...
                          </span>
                        ) : (
                          'Confirm'
                        )}
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel-complete"
                        onClick={() => setCompletingGroup(null)}
                        disabled={isCompleting}
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
});


export default ManageLogistics;