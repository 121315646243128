import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  FaHandHoldingHeart, 
  FaUsers,
  FaExternalLinkAlt, 
  FaDollarSign, 
  FaQuoteLeft,
  FaRecycle, 
  FaChartArea, 
  FaTrophy
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './Header';
import Sidebar from './Sidebar';
import Loader from './Loader';
import ErrorMessage from './ErrorMessage';
import DonationTracker from './DonationTracker';

import { completedDonationsService } from '../services/completedDonationsService';
import { distributionCenterService } from '../services/distributionCenterService';
import { charityService } from '../services/charityService';
import { companyService } from '../services/companyService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';

import '../styles/ImpactDashboard.css';

// Utility functions
const formatNumber = (number) => new Intl.NumberFormat('en-US').format(number);

const formatCurrency = (num) => {
  if (num >= 1000000) return `$${(num / 1000000).toFixed(1)}M`;
  if (num >= 1000) return `$${(num / 1000).toFixed(1)}K`;
  return `$${num.toFixed(0)}`;
};

const formatWeight = (weight) => {
  if (weight >= 1000000) return `${(weight / 1000000).toFixed(1)}M lbs`;
  if (weight >= 1000) return `${(weight / 1000).toFixed(1)}K lbs`;
  return `${weight.toFixed(0)} lbs`;
};

// Subcomponents
const StatCard = ({ icon: Icon, value, label, colorClass }) => (
  <div className="impact-stat-card">
    <div className={`stat-icon ${colorClass}`}>
      <Icon />
    </div>
    <div className="stat-content">
      <p>{value}</p>
      <h3>{label}</h3>
    </div>
  </div>
);

const CharityCard = ({ charity, index }) => (
  <div key={charity.id} className="charity-impact-card">
    <div className="charity-info">
      <img 
        src={charity.logoUrl || '/api/placeholder/48/48'} 
        alt={`${charity.name} logo`}
        className="charity-logo"
      />
      <div className="charity-details">
        <div className="charity-header">
          <h4>{charity.name}</h4>
          <span className="charity-rank">#{index + 1}</span>
        </div>

        <div className="charity-mission">
          <FaQuoteLeft />
          <p>{charity.description}</p>
        </div>

        <div className="charity-metrics">
          <div className="metric">
            <div className="metric-icon green">
              <FaDollarSign />
            </div>
            <div className="metric-data">
              <span>Donation Value</span>
              <strong>{formatCurrency(charity.totalValue)}</strong>
            </div>
          </div>

          <div className="metric">
            <div className="metric-icon purple">
              <FaUsers />
            </div>
            <div className="metric-data">
              <span>Estimated People Helped</span>
              <strong>{formatNumber(charity.peopleHelped)}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const ImpactDashboard = () => {
  const [state, setState] = useState({
    completedDonations: [],
    charities: {},
    loading: true,
    error: null,
    user: null,
    userRole: null,
    userCompany: null,
    companies: []
  });

  // Data fetching
  const fetchData = useCallback(async () => {
    try {
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');

      const userRole = await userService.getUserRole(currentUser.id);

      let allDonations, allCharities, companies;

      if (userRole === 'SystemAdmin') {
        [allDonations, allCharities, companies] = await Promise.all([
          completedDonationsService.getAll(),
          charityService.getAll(),
          companyService.getAll()
        ]);
      } else {
        const userDCIds = userData.distributionCenters || [userData.distributionCenter];
        
        [allDonations, allCharities, companies] = await Promise.all([
          Promise.all(userDCIds.map(dcId => 
            completedDonationsService.getByDistributionCenter(dcId)
          )).then(results => results.flat()),
          charityService.getAll(),
          [await companyService.getById(userData.company)]
        ]);
      }

      const charitiesMap = Object.fromEntries(
        allCharities.map(charity => [charity.id, charity])
      );

      setState(prev => ({
        ...prev,
        completedDonations: allDonations,
        charities: charitiesMap,
        user: userData,
        userRole,
        userCompany: userData.company,
        companies,
        loading: false,
        error: null
      }));

    } catch (error) {
      console.error('Error fetching data:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to load dashboard data. Please try again.',
        loading: false
      }));
      toast.error('Failed to load dashboard data. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Calculate statistics
  const summaryStats = useMemo(() => {
    return state.completedDonations.reduce((acc, donation) => ({
      totalValue: acc.totalValue + donation.fairMarketValue,
      totalTaxSavings: acc.totalTaxSavings + Math.min(
        donation.costBasis + (donation.fairMarketValue - donation.costBasis) / 2,
        2 * donation.costBasis
      ),
      totalWeight: acc.totalWeight + (donation.totalWeight || 0),
      peopleHelped: acc.peopleHelped + donation.quantity
    }), {
      totalValue: 0,
      totalTaxSavings: 0,
      totalWeight: 0,
      peopleHelped: 0
    });
  }, [state.completedDonations]);

  // Calculate top charities
  const topCharities = useMemo(() => {
    const charityImpact = state.completedDonations.reduce((acc, donation) => {
      donation.palletGroups.forEach(group => {
        const charity = state.charities[group.charityId];
        if (!acc[group.charityId]) {
          acc[group.charityId] = {
            id: group.charityId,
            name: charity?.name || 'Unknown Charity',
            logoUrl: charity?.logoUrl,
            description: charity?.description || 'Making a positive impact in our community.',
            totalValue: 0,
            peopleHelped: 0
          };
        }
        const valueShare = (donation.fairMarketValue / donation.palletCount) * group.palletQuantity;
        const peopleShare = (donation.quantity / donation.palletCount) * group.palletQuantity;
        acc[group.charityId].totalValue += valueShare;
        acc[group.charityId].peopleHelped += peopleShare;
      });
      return acc;
    }, {});

    return Object.values(charityImpact)
      .sort((a, b) => b.totalValue - a.totalValue)
      .slice(0, 3);
  }, [state.completedDonations, state.charities]);

  if (state.loading) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="impact-dashboard-container">
          <div className="impact-dashboard-content">
            {/* Dashboard Header */}
            <div className="impact-header">
              <h1><FaChartArea /> Impact Dashboard</h1>
            </div>

            {/* Impact Stats */}
            <div className="impact-stats">
              <StatCard
                icon={FaDollarSign}
                value={formatCurrency(summaryStats.totalValue)}
                label="Value of Donations"
                colorClass="blue"
              />
              <StatCard
                icon={FaHandHoldingHeart}
                value={formatCurrency(summaryStats.totalTaxSavings)}
                label="Est. Tax Savings"
                colorClass="green"
              />
              <StatCard
                icon={FaRecycle}
                value={formatWeight(summaryStats.totalWeight)}
                label="Saved from Landfill"
                colorClass="emerald"
              />
              <StatCard
                icon={FaUsers}
                value={formatNumber(summaryStats.peopleHelped)}
                label="Estimated People Helped"
                colorClass="purple"
              />
            </div>

            {/* Dashboard Main Sections */}
            <div className="dashboard-sections">
              {/* Timeline Section */}
              <div className="timeline-section">
                <div className="timeline-container">
                  <DonationTracker />
                </div>
              </div>

              {/* Charities Section */}
              <div className="charities-section">
                <div className="impact-charities-container">
                  <div className="charities-header">
                    <h3><FaTrophy /> Top Charities</h3>
                    <button 
                      onClick={() => window.location.href = '/reports'}
                      className="btn btn-link"
                    >
                      <span>View Reports</span>
                      <FaExternalLinkAlt />
                    </button>
                  </div>

                  <div className="charity-cards">
                    {topCharities.map((charity, index) => (
                      <CharityCard 
                        key={charity.id}
                        charity={charity}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </div>
  );
};

export default ImpactDashboard;