// src/services/charityService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';

const COLLECTION_NAME = 'charities';

export const charityService = {
  async create(charityData) {
    try {
      const newCharityRef = doc(collection(db, COLLECTION_NAME));
      await setDoc(newCharityRef, {
        ...charityData,
        createdAt: new Date(),
        updatedAt: new Date()
      });
      console.log(`Charity created successfully: ${newCharityRef.id}`);
      return newCharityRef.id;
    } catch (error) {
      console.error(`Error creating charity:`, error);
      throw error;
    }
  },

  async getById(charityId) {
    const charityDoc = await getDoc(doc(db, COLLECTION_NAME, charityId));
    return charityDoc.exists() ? { id: charityDoc.id, ...charityDoc.data() } : null;
  },

  async getAll() {
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async update(charityId, updateData) {
    await updateDoc(doc(db, COLLECTION_NAME, charityId), {
      ...updateData,
      updatedAt: new Date()
    });
  },

  async delete(charityId) {
    await deleteDoc(doc(db, COLLECTION_NAME, charityId));
  },

  async getByAcceptedItems(itemTypes) {
    const q = query(
      collection(db, COLLECTION_NAME),
      where("acceptedItems", "array-contains-any", itemTypes)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async searchByName(name) {
    const q = query(
      collection(db, COLLECTION_NAME),
      where("name", ">=", name),
      where("name", "<=", name + '\uf8ff')
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
};