// src/services/authService.js

import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, sendPasswordResetEmail } from "firebase/auth";
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

const auth = getAuth();

export const authService = {
  async register(email, password) {
    try {
      console.log('Attempting to register user');
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      console.log('User registered successfully');
      return userCredential.user;
    } catch (error) {
      console.error("Error in register:", error.code, error.message);
      throw error;
    }
  },

  async login(email, password) {
    try {
      console.log('Attempting login with Firebase');
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('Firebase login successful, fetching user data');

      const userData = await this.getUserData(user.uid);
      if (userData) {
        console.log('User data fetched:', userData);
        this.setUserSession({
          id: user.uid,
          email: user.email,
          ...userData
        });
        console.log('User session set');
      } else {
        console.error('User document not found in Firestore');
        throw new Error('User data not found');
      }

      return user;
    } catch (error) {
      console.error("Login error:", error.code, error.message);
      switch (error.code) {
        case 'auth/user-not-found':
          throw new Error('No user found with this email. Please check and try again.');
        case 'auth/wrong-password':
          throw new Error('Incorrect password. Please try again.');
        case 'auth/too-many-requests':
          throw new Error('Too many unsuccessful login attempts. Please try again later.');
        default:
          throw new Error('An error occurred during login. Please try again.');
      }
    }
  },

  async logout() {
    console.log('Attempting to log out');
    await signOut(auth);
    localStorage.removeItem('user');
    console.log('User logged out and session cleared');
  },

  async getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(user => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  },

  async getUserData(userId) {
    console.log('Fetching user data for ID:', userId);
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      console.log('User data found');
      return { id: userDoc.id, ...userDoc.data() };
    }
    console.log('No user data found');
    return null;
  },

  setUserSession(userData) {
    console.log('Setting user session:', userData);
    localStorage.setItem('user', JSON.stringify(userData));
  },

  getUserSession() {
    const user = localStorage.getItem('user');
    console.log('Getting user session:', user ? 'User found' : 'No user found');
    return user ? JSON.parse(user) : null;
  },

  async resetPassword(email) {
    try {
      console.log('Attempting to send password reset email');
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
    } catch (error) {
      console.error("Error in resetPassword:", error.code, error.message);
      throw error;
    }
  },
};