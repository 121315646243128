import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { 
    FaChartLine,
  } from 'react-icons/fa';
import Header from './Header';
import Sidebar from './Sidebar';
import '../styles/OptionAnalytics.css';
import DonationLiquidationPlot from '../components/DonationLiquidationPlot';

function OptionAnalytics() {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const checkUser = async () => {
      const user = authService.getUserSession();
      if (!user) {
        navigate('/login');
        return;
      }
      setCurrentUser(user);
      const role = await userService.getUserRole(user.id);
      setUserRole(role);
    };
    checkUser();
  }, [navigate]);


return (
    <div className="app-container">
      <Header user={currentUser} />
      <div className="main-content">
        <Sidebar />
        <div className="option-analytics-container">
        <h1><FaChartLine /> Analyze Your Inventory Options </h1>
          <div className="donation-liquidation-plot-container">
            <DonationLiquidationPlot />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OptionAnalytics;