import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { 
  FaFileInvoiceDollar, 
  FaEye, 
  FaSignature, 
  FaCheck, 
  FaTimes, 
  FaCalendarAlt, 
  FaBuilding, 
  FaDollarSign, 
  FaUser, 
  FaFilter, 
  FaSort, 
  FaFlag, 
  FaClock, 
  FaCheckCircle,
  FaComment
} from 'react-icons/fa';
import { Tooltip } from 'react-tooltip';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Loader from '../components/Loader';
import ErrorMessage from '../components/ErrorMessage';
import { quoteService } from '../services/quoteService';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { distributionCenterService } from '../services/distributionCenterService';
import { messageService } from '../services/messageService';
import '../styles/Quotes.css';

const statusOptions = [
  { value: 'pending', icon: FaClock, color: '#FFA500', label: 'Pending' },
  { value: 'approved', icon: FaCheckCircle, color: '#28A745', label: 'Approved' },
  { value: 'rejected', icon: FaTimes, color: '#DC3545', label: 'Rejected' },
  { value: 'completed', icon: FaFlag, color: '#17a2b8', label: 'Completed' },
];

const StatusIndicator = ({ status }) => {
  const statusOption = statusOptions.find(option => option.value === status) || statusOptions[0];
  const Icon = statusOption.icon;
  
  return (
    <span 
      className="status-indicator" 
      style={{ backgroundColor: statusOption.color }}
      data-tooltip-id="tooltip" 
      data-tooltip-content={statusOption.label}
    >
      <Icon /> {statusOption.label}
    </span>
  );
};

const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);
};

const Quotes = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const quoteDetailsRef = useRef(null);

  const [state, setState] = useState({
    quotes: [],
    user: null,
    loading: true,
    error: null,
    filters: {
      status: 'all',
      dateRange: { start: null, end: null },
      searchTerm: '',
    },
    sort: {
      field: 'date',
      direction: 'desc',
    },
    pagination: {
      currentPage: 1,
      itemsPerPage: 10,
    },
    distributionCenters: [],
    selectedDC: 'all',
    initialLoadComplete: false,
  });

  const [selectedQuote, setSelectedQuote] = useState(null);
  const [signature, setSignature] = useState(null);
  const [approverName, setApproverName] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');

  // Load initial data
  const fetchData = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, loading: true, error: null }));

      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const userData = await userService.getById(currentUser.id);
      if (!userData) throw new Error('User data not found');

      const userDCs = userData.distributionCenters || [userData.distributionCenter];
      const dcs = await Promise.all(userDCs.map(dcId => 
        distributionCenterService.getById(dcId)
      ));

      const quotes = await Promise.all(dcs.map(dc => 
        quoteService.fetchAllQuotes(dc.id)
      ));

      const flattenedQuotes = quotes.flat();
      
      setState(prev => ({ 
        ...prev, 
        user: userData, 
        quotes: flattenedQuotes,
        distributionCenters: dcs,
        loading: false,
        initialLoadComplete: true,
      }));

    } catch (error) {
      console.error('Error fetching data:', error);
      setState(prev => ({ 
        ...prev, 
        error: error.message, 
        loading: false, 
        initialLoadComplete: true 
      }));
      toast.error('Failed to load quotes. Please refresh the page.');
    }
  }, []);

  // Handle loading quote details
  const loadQuoteDetails = useCallback(async (quote) => {
    try {
      if (quote.status === 'pending') {
        const pdfBlob = await quoteService.generatePDF(quote);
        const url = URL.createObjectURL(pdfBlob);
        setPdfUrl(url);
      } else {
        setPdfUrl(quote.pdfUrl);
      }

      if (quote.approverId) {
        const name = await userService.getUserNameById(quote.approverId);
        setApproverName(name);
      } else {
        setApproverName('');
      }

      setSelectedQuote(quote);
      
      // Scroll to quote details after a short delay to ensure rendering
      setTimeout(() => {
        if (quoteDetailsRef.current) {
          quoteDetailsRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }
      }, 100);

    } catch (error) {
      console.error('Error loading quote details:', error);
      toast.error('Failed to load quote details');
    }
  }, []);

  // Load initial data
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle URL routing after initial data load
  useEffect(() => {
    if (state.quotes.length > 0 && quoteId) {
      const quote = state.quotes.find(q => q.id === quoteId);
      if (quote) {
        loadQuoteDetails(quote);
      } else {
        toast.error('Quote not found');
        navigate('/quotes');
      }
    }
  }, [quoteId, state.quotes, loadQuoteDetails, navigate]);

  const handleViewQuote = useCallback((quote) => {
    navigate(`/quotes/${quote.id}`);
  }, [navigate]);

  const handleCloseQuoteDetails = useCallback(() => {
    setSelectedQuote(null);
    setPdfUrl(null);
    setRejectionReason('');
    navigate('/quotes');
  }, [navigate]);

  const handleSign = useCallback(() => {
    if (signature) {
      const signatureImage = signature.toDataURL();
      setSelectedQuote((prevQuote) => ({ ...prevQuote, signature: signatureImage }));
    }
  }, [signature]);

  const handleClearSignature = useCallback(() => {
    if (signature) {
      signature.clear();
      setSelectedQuote((prevQuote) => ({ ...prevQuote, signature: null }));
    }
  }, [signature]);

  const handleGenerateSignature = useCallback(() => {
    if (!state.user?.name) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = 300;
    canvas.height = 100;

    context.font = '40px "Brush Script MT", "Brush Script Std", "Lucida Calligraphy", "Lucida Handwriting", "Apple Chancery", cursive';
    context.fillStyle = 'black';
    
    const textWidth = context.measureText(state.user.name).width;
    const xPosition = (canvas.width - textWidth) / 2;
    
    context.fillText(state.user.name, xPosition, 60);

    const signatureImage = canvas.toDataURL();
    setSelectedQuote((prevQuote) => ({ ...prevQuote, signature: signatureImage }));
    if (signature?.fromDataURL) {
      signature.fromDataURL(signatureImage);
    }
  }, [state.user, signature]);

  const handleApproveQuote = useCallback(async () => {
    if (!selectedQuote?.signature) return;

    try {
      setState(prev => ({ ...prev, loading: true }));
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const approvalData = {
        signature: selectedQuote.signature,
        approverId: currentUser.id,
        approvalTimestamp: new Date().toISOString()
      };

      await quoteService.approveQuote(selectedQuote.id, approvalData);
      
      const quoteCreator = await userService.getById(selectedQuote.adminUserId);
      
      if (quoteCreator?.phone) {
        try {
          const approverName = await userService.getUserNameById(currentUser.id);
          await messageService.sendQuoteApprovalNotification(
            quoteCreator.phone,
            {
              ...selectedQuote,
              approverName,
              approvalTimestamp: approvalData.approvalTimestamp
            }
          );
        } catch (messageError) {
          console.error('Error sending approval notification:', messageError);
        }
      }

      await fetchData();
      handleCloseQuoteDetails();
      toast.success('Quote approved successfully');
    } catch (error) {
      console.error('Error approving quote:', error);
      toast.error('Failed to approve quote. Please try again.');
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [selectedQuote, fetchData, handleCloseQuoteDetails]);

  const handleRejectQuote = useCallback(async () => {
    if (!selectedQuote || !rejectionReason.trim()) return;

    try {
      setState(prev => ({ ...prev, loading: true }));
      const currentUser = authService.getUserSession();
      if (!currentUser) throw new Error('No authenticated user found');

      const rejectionData = {
        reason: rejectionReason,
        userId: currentUser.id,
        rejectionTimestamp: new Date().toISOString()
      };

      await quoteService.rejectQuote(selectedQuote.id, rejectionData);
      
      const quoteCreator = await userService.getById(selectedQuote.adminUserId);
      
      if (quoteCreator?.phone) {
        try {
          const rejectedBy = await userService.getUserNameById(currentUser.id);
          await messageService.sendQuoteRejectionNotification(
            quoteCreator.phone,
            {
              ...selectedQuote,
              rejectedBy,
              rejectionReason,
              rejectionTimestamp: rejectionData.rejectionTimestamp
            }
          );
        } catch (messageError) {
          console.error('Error sending rejection notification:', messageError);
        }
      }

      await fetchData();
      handleCloseQuoteDetails();
      toast.success('Quote rejected successfully');
    } catch (error) {
      console.error('Error rejecting quote:', error);
      toast.error('Failed to reject quote. Please try again.');
    } finally {
      setState(prev => ({ ...prev, loading: false }));
    }
  }, [selectedQuote, rejectionReason, fetchData, handleCloseQuoteDetails]);

  const handleFilter = useCallback((name, value) => {
    setState(prev => ({
      ...prev,
      filters: { ...prev.filters, [name]: value }
    }));
  }, []);

  const handleSort = useCallback((field) => {
    setState(prev => ({
      ...prev,
      sort: {
        field,
        direction: prev.sort.field === field && prev.sort.direction === 'asc' ? 'desc' : 'asc'
      }
    }));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const filteredAndSortedQuotes = useMemo(() => {
    return state.quotes
      .filter(quote => {
        const searchLower = state.filters.searchTerm.toLowerCase();
        const matchesSearch = quote.id.toLowerCase().includes(searchLower);
        const matchesStatus = state.filters.status === 'all' || quote.status === state.filters.status;
        const matchesDateRange = 
          (!state.filters.dateRange.start || !state.filters.dateRange.end) ||
          (new Date(quote.date) >= new Date(state.filters.dateRange.start) &&
           new Date(quote.date) <= new Date(state.filters.dateRange.end));
        const matchesDC = state.selectedDC === 'all' || quote.distributionCenterId === state.selectedDC;

        return matchesSearch && matchesStatus && matchesDateRange && matchesDC;
      })
      .sort((a, b) => {
        if (a[state.sort.field] < b[state.sort.field]) return state.sort.direction === 'asc' ? -1 : 1;
        if (a[state.sort.field] > b[state.sort.field]) return state.sort.direction === 'asc' ? 1 : -1;
        return 0;
      });
  }, [state.quotes, state.filters, state.sort, state.selectedDC]);

  const paginatedQuotes = useMemo(() => {
    const startIndex = (state.pagination.currentPage - 1) * state.pagination.itemsPerPage;
    return filteredAndSortedQuotes.slice(startIndex, startIndex + state.pagination.itemsPerPage);
  }, [filteredAndSortedQuotes, state.pagination]);

  const totalPages = Math.ceil(filteredAndSortedQuotes.length / state.pagination.itemsPerPage);

  if (state.loading && !state.initialLoadComplete) return <Loader />;
  if (state.error) return <ErrorMessage message={state.error} />;

  return (
    <div className="app-container">
      <Header user={state.user} />
      <div className="main-content">
        <Sidebar />
        <div className="quotes-container">
          {(!quoteId || selectedQuote) && (
            <>
              <h1><FaFileInvoiceDollar /> Quotes</h1>
              
              <FilterBar 
                filters={state.filters}
                onFilterChange={handleFilter}
                statusOptions={statusOptions}
                distributionCenters={state.distributionCenters}
                selectedDC={state.selectedDC}
                onDCChange={(value) => setState(prev => ({ ...prev, selectedDC: value }))}
              />

              <QuoteTable 
                quotes={paginatedQuotes}
                onViewQuote={handleViewQuote}
                sort={state.sort}
                onSort={handleSort}
              />

              <Pagination
                currentPage={state.pagination.currentPage}
                totalPages={totalPages}
                onPageChange={(page) => setState(prev => ({ 
                  ...prev, 
                  pagination: { ...prev.pagination, currentPage: page } 
                }))}
              />
            </>
          )}

          {selectedQuote && (
            <div className="quote-details-container" ref={quoteDetailsRef}>
              <QuoteDetails 
                quote={selectedQuote}
                signature={signature}
                setSignature={setSignature}
                onSign={handleSign}
                onClearSignature={handleClearSignature}
                onApprove={handleApproveQuote}
                onReject={handleRejectQuote}
                rejectionReason={rejectionReason}
                setRejectionReason={setRejectionReason}
                onClose={handleCloseQuoteDetails}
                approverName={approverName}
                onGenerateSignature={handleGenerateSignature}
                userName={state.user ? state.user.name : ''}
              />
              <QuotePDF pdfUrl={pdfUrl} />
            </div>
          )}
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Tooltip id="tooltip" />
    </div>
  );
};

const FilterBar = React.memo(({ filters, onFilterChange, statusOptions, distributionCenters, selectedDC, onDCChange }) => (
<div className="filters">
<div className="filter-group">
<FaFilter className="icon" />
<input 
type="text" 
placeholder="Search by Quote ID" 
value={filters.searchTerm}
onChange={(e) => onFilterChange('searchTerm', e.target.value)}
/>
</div>
<div className="filter-group">
<FaCalendarAlt className="icon" />
<select 
value={filters.status} 
onChange={(e) => onFilterChange('status', e.target.value)}
>
<option value="all">All Statuses</option>
{statusOptions.map(option => (
<option key={option.value} value={option.value}>{option.label}</option>
))}
</select>
</div>
<div className="filter-group">
<FaCalendarAlt className="icon" />
<input 
type="date" 
value={filters.dateRange.start || ''} 
onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, start: e.target.value })}
/>
<span>to</span>
<input 
type="date" 
value={filters.dateRange.end || ''} 
onChange={(e) => onFilterChange('dateRange', { ...filters.dateRange, end: e.target.value })}
/>
</div>
<div className="filter-group">
<FaBuilding className="icon" />
<select 
value={selectedDC} 
onChange={(e) => onDCChange(e.target.value)}
>
<option value="all">All Distribution Centers</option>
{distributionCenters.map(dc => (
<option key={dc.id} value={dc.id}>{dc.name}</option>
))}
</select>
</div>
<button className="btn btn-reset" onClick={() => {
onFilterChange('status', 'all');
onFilterChange('dateRange', { start: null, end: null });
onFilterChange('searchTerm', '');
onDCChange('all');
}}>Reset Filters</button>
</div>
));

const QuoteTable = React.memo(({ quotes, onViewQuote, sort, onSort }) => {
const renderSortIcon = (field) => {
if (sort.field !== field) return <FaSort />;
return sort.direction === 'asc' ? <FaSort className="asc" /> : <FaSort className="desc" />;
};

return (
<div className="quotes-table-container">
<table className="quotes-table">
<thead>
<tr>
<th onClick={() => onSort('id')}>Quote ID {renderSortIcon('id')}</th>
<th onClick={() => onSort('date')}>Date {renderSortIcon('date')}</th>
<th onClick={() => onSort('amount')}>Amount {renderSortIcon('amount')}</th>
<th onClick={() => onSort('status')}>Status {renderSortIcon('status')}</th>
<th>Actions</th>
</tr>
</thead>
<tbody>
{quotes.map(quote => (
<tr key={quote.id}>
<td data-label="Quote ID">{quote.id}</td>
<td data-label="Date">{new Date(quote.date).toLocaleDateString()}</td>
<td data-label="Amount">{formatCurrency(quote.amount)}</td>
<td data-label="Status">
  <StatusIndicator status={quote.status} />
</td>
<td data-label="Actions">
  <button className="btn btn-view" onClick={() => onViewQuote(quote)}>
    <FaEye /> View
  </button>
</td>
</tr>
))}
</tbody>
</table>
</div>
);
});

const QuoteDetails = React.memo(({ 
quote, 
signature, 
setSignature, 
onSign, 
onClearSignature, 
onApprove,
onReject,
rejectionReason,
setRejectionReason,
onClose,
approverName,
onGenerateSignature,
userName
}) => (
<div className="quote-details">
<div className="quote-details-header">
<h2>Quote Details</h2>
<button className="btn-close" onClick={onClose}>
<FaTimes />
</button>
</div>
<div className="quote-information">
<h3>Quote Information</h3>
<div className="info-group">
<strong><FaFileInvoiceDollar /> Quote ID:</strong>
<span>{quote.id}</span>
</div>
<div className="info-group">
<strong><FaCalendarAlt /> Date:</strong>
<span>{new Date(quote.date).toLocaleDateString()}</span>
</div>
<div className="info-group">
<strong><FaDollarSign /> Amount:</strong>
<span>{formatCurrency(quote.amount)}</span>
</div>
<div className="info-group">
<strong><FaBuilding /> Distribution Center:</strong>
<span>{quote.distributionCenterName}</span>
</div>
<div className="info-group">
<strong><FaUser /> Requester:</strong>
<span>{quote.requestorName}</span>
</div>
{quote.validityPeriod && (
<div className="info-group">
<strong><FaCalendarAlt /> Valid Until:</strong>
<span>{new Date(quote.validityPeriod).toLocaleDateString()}</span>
</div>
)}
</div>

{quote.status === 'approved' && (
<div className="approval-information">
<h3>Approval Information</h3>
<div className="info-group">
<strong><FaUser /> Approved By:</strong>
<span>{approverName}</span>
</div>
<div className="info-group">
<strong><FaCalendarAlt /> Approval Date:</strong>
<span>{new Date(quote.approvalTimestamp).toLocaleString()}</span>
</div>
</div>
)}

{quote.status === 'rejected' && (
<div className="rejection-information">
<h3>Rejection Information</h3>
<div className="info-group">
<strong><FaUser /> Rejected By:</strong>
<span>{quote.rejectedBy}</span>
</div>
<div className="info-group">
<strong><FaCalendarAlt /> Rejection Date:</strong>
<span>{new Date(quote.rejectionTimestamp).toLocaleString()}</span>
</div>
<div className="info-group">
<strong><FaComment /> Rejection Reason:</strong>
<span>{quote.rejectionReason}</span>
</div>
</div>
)}

{quote.status === 'pending' && (
<div className="quote-actions">
<div className="signature-container">
<h3>Signature</h3>
<SignatureCanvas
ref={(ref) => setSignature(ref)}
canvasProps={{ className: 'signature-canvas' }}
/>
<div className="signature-actions">
<button className="btn btn-sign" onClick={onSign} disabled={!signature}>
<FaSignature /> Sign
</button>
<button className="btn btn-generate-signature" onClick={onGenerateSignature}>
<FaUser /> Generate Signature
</button>
<button className="btn btn-clear" onClick={onClearSignature}>
Clear Signature
</button>
</div>
<button 
className="btn btn-approve" 
onClick={onApprove} 
disabled={!quote.signature}
>
<FaCheck /> Approve Quote
</button>
</div>

<div className="rejection-container">
<h3>Reject Quote</h3>
<textarea
className="rejection-reason"
placeholder="Enter reason for rejection..."
value={rejectionReason}
onChange={(e) => setRejectionReason(e.target.value)}
/>
<button 
className="btn btn-reject" 
onClick={onReject}
disabled={!rejectionReason.trim()}
>
<FaTimes /> Reject Quote
</button>
</div>
</div>
)}
</div>
));

const QuotePDF = React.memo(({ pdfUrl }) => (
<div className="quote-pdf-container">
<h3>Quote PDF</h3>
{pdfUrl ? (
<iframe
src={pdfUrl}
title="Quote PDF"
width="100%"
height="600px"
/>
) : (
<p>Loading PDF...</p>
)}
</div>
));

const Pagination = React.memo(({ currentPage, totalPages, onPageChange }) => {
return (
<div className="pagination">
<button 
onClick={() => onPageChange(currentPage - 1)} 
disabled={currentPage === 1}
>
Previous
</button>
<span>{currentPage} of {totalPages}</span>
<button 
onClick={() => onPageChange(currentPage + 1)} 
disabled={currentPage === totalPages}
>
Next
</button>
</div>
);
});

export default Quotes;