import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { quoteService } from './quoteService';
import { logisticsService } from './logisticsService';

const COLLECTION_NAME = 'completedDonations';

class CompletedDonationError extends Error {
  constructor(message, code) {
    super(message);
    this.name = 'CompletedDonationError';
    this.code = code;
  }
}

const validateData = (data) => {
  const { quoteId, distributionCenterId, palletGroups, fmvAssessmentUrls, taxReceiptUrls } = data;
  
  if (!quoteId) throw new CompletedDonationError('Quote ID is required', 'MISSING_QUOTE_ID');
  if (!distributionCenterId) throw new CompletedDonationError('Distribution Center ID is required', 'MISSING_DC_ID');
  if (!Array.isArray(palletGroups) || palletGroups.length === 0) {
    throw new CompletedDonationError('Pallet groups must be a non-empty array', 'INVALID_PALLET_GROUPS');
  }
  if (!fmvAssessmentUrls || Object.keys(fmvAssessmentUrls).length === 0) {
    throw new CompletedDonationError('FMV assessment URLs are required', 'MISSING_FMV_URLS');
  }
  if (!taxReceiptUrls || Object.keys(taxReceiptUrls).length === 0) {
    throw new CompletedDonationError('Tax receipt URLs are required', 'MISSING_TAX_RECEIPTS');
  }

  // Validate each pallet group
  palletGroups.forEach(group => {
    if (!group.itemId || !group.charityId || !group.charityName || !group.palletGroup) {
      throw new CompletedDonationError('Invalid pallet group data', 'INVALID_PALLET_GROUP');
    }
  });
};

export const completedDonationsService = {
  async createCompletedDonation(
    quoteId,
    taxReceiptUrls,
    quotePdfUrl,
    palletGroups,
    distributionCenterId,
    fmvAssessmentUrls,
    description,
    totalCostBasis,
    totalWeight,
    quantity,
    unitOfMeasure
  ) {
    try {
      // Basic validation
      if (!quoteId || !distributionCenterId || !Array.isArray(palletGroups) || palletGroups.length === 0) {
        throw new CompletedDonationError('Missing required parameters');
      }
  
      // Validate URLs exist
      if (!fmvAssessmentUrls || Object.keys(fmvAssessmentUrls).length === 0) {
        throw new CompletedDonationError('Missing FMV assessment URL');
      }
  
      if (!taxReceiptUrls || Object.keys(taxReceiptUrls).length === 0) {
        throw new CompletedDonationError('Missing tax receipt URLs');
      }
  
      // Get quote for additional data
      const quote = await quoteService.getById(quoteId);
      if (!quote) {
        throw new CompletedDonationError('Quote not found', 'QUOTE_NOT_FOUND');
      }
  
      // Prepare cleaned data
      const cleanedData = {
        id: doc(collection(db, COLLECTION_NAME)).id,
        quoteId,
        companyId: quote.companyId,
        donationId: quote.donationId,
        description: description?.trim() || 'No description available',
        quantity: Number(quantity) || 0,
        unitOfMeasure: unitOfMeasure || 'Units',
        palletGroups: palletGroups.map(group => ({
          itemId: group.itemId,
          charityId: group.charityId,
          charityName: group.charityName,
          palletGroup: group.palletGroup,
          palletQuantity: Number(group.palletQuantity) || 0
        })),
        palletCount: palletGroups.reduce((sum, group) => sum + (Number(group.palletQuantity) || 0), 0),
        totalWeight: Number(totalWeight) || 0,
        fairMarketValue: Number(quote.fairMarketValue) || 0,
        costBasis: Number(totalCostBasis) || 0,
        fmvAssessmentUrls,
        taxReceiptUrls,
        quotePdfUrl: quotePdfUrl || null,
        distributionCenterId,
        status: 'completed',
        completionDate: quote.completionDate || new Date().toISOString(),
        createdAt: new Date().toISOString(),
        lastUpdatedAt: new Date().toISOString()
      };
  
      // Create the document in Firestore
      const docRef = doc(collection(db, COLLECTION_NAME), cleanedData.id);
      await setDoc(docRef, cleanedData);
      
      return cleanedData;
  
    } catch (error) {
      console.error('Error creating completed donation:', error);
      throw error instanceof CompletedDonationError ? error : 
        new CompletedDonationError(error.message, 'CREATE_ERROR');
    }
  },

  async getById(id) {
    try {
      if (!id) throw new CompletedDonationError('ID is required', 'INVALID_PARAMETER');
      const docRef = doc(collection(db, COLLECTION_NAME), id);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
    } catch (error) {
      throw new CompletedDonationError(error.message, 'GET_ERROR');
    }
  },

  async getAll() {
    try {
      const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDonationError(error.message, 'GET_ALL_ERROR');
    }
  },

  async getByCompany(companyId) {
    try {
      if (!companyId) throw new CompletedDonationError('Company ID is required', 'INVALID_PARAMETER');
      const q = query(collection(db, COLLECTION_NAME), where("companyId", "==", companyId));
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByDateRange(startDate, endDate) {
    try {
      if (!startDate || !endDate) {
        throw new CompletedDonationError('Start and end dates are required', 'INVALID_PARAMETER');
      }
      const q = query(
        collection(db, COLLECTION_NAME),
        where("completionDate", ">=", startDate),
        where("completionDate", "<=", endDate)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByDistributionCenter(distributionCenterId) {
    try {
      if (!distributionCenterId) {
        throw new CompletedDonationError('Distribution center ID is required', 'INVALID_PARAMETER');
      }
      const q = query(
        collection(db, COLLECTION_NAME),
        where("distributionCenterId", "==", distributionCenterId)
      );
      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByQuoteId(quoteId) {
    try {
      if (!quoteId) throw new CompletedDonationError('Quote ID is required', 'INVALID_PARAMETER');
      const q = query(collection(db, COLLECTION_NAME), where("quoteId", "==", quoteId));
      const querySnapshot = await getDocs(q);
      const docs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      return docs[0] || null;
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getWithDetails() {
    try {
      const donations = await this.getAll();
      return Promise.all(donations.map(async (donation) => {
        try {
          const quote = await quoteService.getById(donation.quoteId);
          const tasks = donation.logisticsTaskIds
            ? await Promise.all(
                donation.logisticsTaskIds.map(taskId => 
                  logisticsService.getTaskById(taskId)
                )
              )
            : [];

          return {
            ...donation,
            quote: quote || null,
            logisticsTasks: tasks.filter(task => task !== null)
          };
        } catch (error) {
          console.warn(`Error fetching details for donation ${donation.id}:`, error);
          return {
            ...donation,
            quote: null,
            logisticsTasks: [],
            error: error.message
          };
        }
      }));
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByCharityId(charityId) {
    try {
      if (!charityId) throw new CompletedDonationError('Charity ID is required', 'INVALID_PARAMETER');
      const donations = await this.getAll();
      return donations.filter(donation => 
        donation.palletGroups?.some(group => group.charityId === charityId)
      );
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getRecentDonations(limit = 10) {
    try {
      if (typeof limit !== 'number' || limit <= 0) {
        throw new CompletedDonationError('Limit must be a positive number', 'INVALID_PARAMETER');
      }
      const donations = await this.getAll();
      return donations
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        .slice(0, limit);
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByValue(minValue, maxValue) {
    try {
      if (typeof minValue !== 'number' || typeof maxValue !== 'number') {
        throw new CompletedDonationError('Min and max values must be numbers', 'INVALID_PARAMETER');
      }
      const donations = await this.getAll();
      return donations.filter(donation =>
        donation.fairMarketValue >= minValue && 
        donation.fairMarketValue <= maxValue
      );
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  },

  async getByPalletCount(minCount, maxCount) {
    try {
      if (typeof minCount !== 'number' || typeof maxCount !== 'number') {
        throw new CompletedDonationError('Min and max counts must be numbers', 'INVALID_PARAMETER');
      }
      const donations = await this.getAll();
      return donations.filter(donation =>
        donation.palletCount >= minCount && 
        donation.palletCount <= maxCount
      );
    } catch (error) {
      throw new CompletedDonationError(error.message, 'QUERY_ERROR');
    }
  }
};

export default completedDonationsService;