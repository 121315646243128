import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { authService } from '../services/authService';
import { userService } from '../services/userService';
import { FaSearch, FaFileAlt, FaUpload, FaQuestionCircle, FaTruck, FaFileInvoiceDollar, FaCalendarCheck, FaBox, FaChartLine, FaUsers } from 'react-icons/fa';
import Header from './Header';
import Sidebar from './Sidebar';
import '../styles/Help.css';

const StepByStepGuide = () => {
    const steps = [
      {
        icon: <FaSearch />,
        title: "Identify Excess Inventory",
        description: "Regularly review your inventory to identify items that are overstocked, approaching expiration, or no longer needed."
      },
      {
        icon: <FaFileAlt />,
        title: "Create a CSV File",
        description: "Prepare a CSV file containing details of the items you wish to donate. Include product name, quantity, expiration date (if applicable), and other relevant details.",
        button: {
            text: "Download CSV Template",
            action: () => window.open("https://firebasestorage.googleapis.com/v0/b/donatingsimplified.appspot.com/o/templates%2Fupload_demo_inventory.csv?alt=media&token=f5fd8090-4dcb-4ec1-a783-cc2d7a1f4bcf", "_blank")
          }
      },
      {
        icon: <FaUpload />,
        title: "Upload CSV on Start New Donation Page",
        description: "Navigate to the \"Start New Donation\" page and upload your prepared CSV file. The system will process this information to initiate the donation process."
      },
      {
        icon: <FaTruck />,
        title: "Donating Simplified Logistics",
        description: "Our team will review the uploaded information and handle the logistics:",
        subItems: [
          "We'll identify suitable local charities that can benefit from your donation.",
          "We'll arrange transportation from your distribution center to the chosen charity.",
          "We'll handle all necessary paperwork for tax deduction purposes."
        ]
      },
      {
        icon: <FaFileInvoiceDollar />,
        title: "Approve Shipping & Pickup Date",
        description: "Easily view, approve, and manage your Purchase Orders and Pickup Requests.",
        subItems: [
            "Once logistics are arranged, you'll receive a notification on the Purchase Order page to confirm the shipping order.",
            "Then you'll receive a notification on the Approval Requests page with the scheduled pickup date and time."
        ]
      },
      {
        icon: <FaBox />,
        title: "Prepare for Pickup",
        description: "Ensure the donated items are properly packaged and ready for pickup at the scheduled time. Refer to the Bill of Lading provided on the Approval Requests page for the donation item."
      },
      {
        icon: <FaChartLine />,
        title: "Track Performance",
        description: "Use the Reports page to view detailed information about your donations, their impact, and any tax benefits. You can customize & download comprehensive reports and any tax documents here."
      }
    ];
  
    return (
      <div className="help-container">
        <h1>Step-by-Step How To Guide</h1>
        <div className="steps-container">
          {steps.map((step, index) => (
            <div key={index} className="step">
              <div className="step-icon">{step.icon}</div>
              <div className="step-content">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
                {step.subItems && (
                  <ul>
                    {step.subItems.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                )}
                {step.button && (
                  <button className="download-button" onClick={step.button.action}>
                    {step.button.text}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  function Help() {
    const [currentUser, setCurrentUser] = useState(null);
    const [userRole, setUserRole] = useState(null);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkUser = async () => {
        const user = authService.getUserSession();
        if (!user) {
          navigate('/login');
          return;
        }
        setCurrentUser(user);
        const role = await userService.getUserRole(user.id);
        setUserRole(role);
      };
      checkUser();
    }, [navigate]);
  
    return (
      <div className="app-container">
        <Header user={currentUser} />
        <div className="main-content">
          <Sidebar />
          <div className="help-container">
            <h1><FaQuestionCircle /> Help & Guide</h1>
            <StepByStepGuide />
          </div>
        </div>
      </div>
    );
  }
  
  export default Help;