// src/services/distributionCenterService.js

import { db } from '../firebase/config';
import { collection, doc, setDoc, getDoc, getDocs, updateDoc, deleteDoc, query, where } from 'firebase/firestore';

const COLLECTION_NAME = 'distributionCenters';

export const distributionCenterService = {
  async create(dcData) {
    const newDCRef = doc(collection(db, COLLECTION_NAME));
    await setDoc(newDCRef, dcData);
    return newDCRef.id;
  },

  async getById(dcId) {
    const dcDoc = await getDoc(doc(db, COLLECTION_NAME, dcId));
    return dcDoc.exists() ? { id: dcDoc.id, ...dcDoc.data() } : null;
  },

  async getByCompany(companyId) {
    const q = query(collection(db, COLLECTION_NAME), where("company", "==", companyId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async update(dcId, updateData) {
    await updateDoc(doc(db, COLLECTION_NAME, dcId), updateData);
  },

  async getAll() {
    const querySnapshot = await getDocs(collection(db, COLLECTION_NAME));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  },

  async delete(dcId) {
    await deleteDoc(doc(db, COLLECTION_NAME, dcId));
  }
};