// src/components/CreateNewCharity.js

import React, { useState, useEffect, useCallback } from 'react';
import { 
  FaUpload, 
  FaFileAlt, 
  FaTrash, 
  FaSpinner, 
  FaHandHoldingHeart, 
  FaBuilding, 
  FaListAlt, 
  FaPlus, 
  FaMinus,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaGlobe,
  FaBoxes,
  FaFileInvoice,
  FaClock,
  FaInfoCircle,
  FaExclamationTriangle,
} from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Papa from 'papaparse';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { authService } from '../services/authService';
import { charityService } from '../services/charityService';
import { mapService } from '../services/mapService';
import { userService } from '../services/userService';

import '../styles/CreateNewCharity.css';
import CharityCards from './CharityCards';

// Validation patterns
const PATTERNS = {
  EMAIL: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  PHONE: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  URL: /^https?:\/\/.+\..+/,
  ZIP: /^\d{5}(-\d{4})?$/,
  TAX_ID: /^\d{2}-\d{7}$/,
  TIME: /^(?:(0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:AM|PM)\s*-\s*(0?[1-9]|1[0-2]):[0-5][0-9]\s?(?:AM|PM)|Closed)$/i
};

function CreateNewCharity() {
  const [user, setUser] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [fileName, setFileName] = useState('');
  const [allCharities, setAllCharities] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [charityMode, setCharityMode] = useState('automatic');

  const fetchAllCharities = useCallback(async () => {
    try {
      const charities = await charityService.getAll();
      setAllCharities(charities);
    } catch (error) {
      console.error('Error fetching charities:', error);
      toast.error('Failed to fetch charities. Please refresh the page.');
    }
  }, []);

  useEffect(() => {
    const fetchUserAndCharities = async () => {
      setIsLoading(true);
      try {
        const currentUser = await authService.getCurrentUser();
        const userData = authService.getUserSession();
  
        if (currentUser) {
          if (userData?.role === 'SystemAdmin') {
            const fullUserData = await userService.getById(userData.id);
            setUser(fullUserData);
            await fetchAllCharities();
          } else {
            toast.error('You do not have permission to access this page.');
          }
        } else {
          toast.error('Please log in to access this page.');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('An error occurred while loading the page.');
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserAndCharities();
  }, [fetchAllCharities]);

  const validateCsvData = (data) => {
    const requiredFields = [
      'name', 'logourl', 'street', 'city', 'state', 'zipcode', 
      'country', 'phone', 'email', 'website', 'accepteditems', 
      'description', 'taxid', 'donationinstructions'
    ];
    const errors = [];
  
    data.forEach((row, index) => {
      // Check required fields
      requiredFields.forEach(field => {
        if (!row[field]) {
          errors.push(`Row ${index + 2}: Missing ${field}`);
        }
      });
  
      // Validate formats
      if (row.email && !PATTERNS.EMAIL.test(row.email)) {
        errors.push(`Row ${index + 2}: Invalid email format`);
      }
      if (row.phone && !PATTERNS.PHONE.test(row.phone)) {
        errors.push(`Row ${index + 2}: Invalid phone format`);
      }
      if (row.website && !PATTERNS.URL.test(row.website)) {
        errors.push(`Row ${index + 2}: Invalid website URL`);
      }
      if (row.logourl && !PATTERNS.URL.test(row.logourl)) {
        errors.push(`Row ${index + 2}: Invalid logo URL`);
      }
      if (row.zipcode && !PATTERNS.ZIP.test(row.zipcode)) {
        errors.push(`Row ${index + 2}: Invalid ZIP code`);
      }
      if (row.taxid && !PATTERNS.TAX_ID.test(row.taxid)) {
        errors.push(`Row ${index + 2}: Invalid Tax ID format (XX-XXXXXXX)`);
      }

      // Validate operating hours if provided
      ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
        if (row[day] && !PATTERNS.TIME.test(row[day])) {
          errors.push(`Row ${index + 2}: Invalid time format for ${day}. Use format "HH:MM AM/PM - HH:MM PM" or "Closed"`);
        }
      });
    });
  
    return errors;
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    setFileName(file.name);
    setError('');
    setCsvData([]);
  
    Papa.parse(file, {
      complete: (result) => {
        if (result.data && result.data.length > 1) {
          const headers = result.data[0].map(header => header.trim().toLowerCase());
          const parsedData = result.data.slice(1)
            .filter(row => row.length === headers.length && row.some(cell => cell !== ''))
            .map(row => {
              const item = {};
              headers.forEach((header, index) => {
                item[header] = row[index]?.trim() || '';
              });
              return item;
            });
  
          const validationErrors = validateCsvData(parsedData);
          if (validationErrors.length > 0) {
            setError(validationErrors.join('\n'));
            toast.error('Validation errors found in CSV file. Please check the error messages below.');
          } else {
            setCsvData(parsedData);
            toast.success('CSV file processed successfully');
          }
        } else {
          setError('The CSV file is empty or invalid');
          toast.error('Invalid CSV file');
        }
      },
      header: false,
      error: (error) => {
        console.error("CSV parsing error:", error);
        setError(`Error parsing CSV: ${error.message}`);
        toast.error('Error parsing CSV file');
      }
    });
  };

  const formatPhoneNumber = (phone) => {
    const cleaned = phone.replace(/\D/g, '');
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  };

  const handleSubmit = async (e, manualCharity = null) => {
    e.preventDefault();
    
    if (charityMode === 'automatic' && csvData.length === 0) {
      toast.error('Please upload a valid CSV file');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setSuccessMessage('');
  
    const createdCharities = [];
    const failedCharities = [];
  
    try {
      const charitiesToProcess = charityMode === 'automatic' ? csvData : [manualCharity];
  
      for (const charity of charitiesToProcess) {
        try {
          // Format phone number
          charity.phone = formatPhoneNumber(charity.phone);

          // Geocode address
          const address = `${charity.street}, ${charity.city}, ${charity.state} ${charity.zipcode}, ${charity.country}`;
          let geocodedLocation = null;
          
          try {
            geocodedLocation = await mapService.geocodeAddress(address);
          } catch (geocodeError) {
            console.warn(`Failed to geocode address for ${charity.name}:`, geocodeError);
          }

          const newCharityData = {
            name: charity.name,
            logoUrl: charity.logourl,
            address: {
              street: charity.street,
              city: charity.city,
              state: charity.state,
              zip: charity.zipcode,
              country: charity.country,
              latitude: geocodedLocation?.latitude || null,
              longitude: geocodedLocation?.longitude || null,
            },
            contactInfo: {
              phone: charity.phone,
              email: charity.email,
              website: charity.website
            },
            acceptedItems: charity.accepteditems.split(/[,\s]+/).filter(Boolean),
            description: charity.description,
            taxId: charity.taxid,
            operatingHours: {
              monday: charity.monday || '',
              tuesday: charity.tuesday || '',
              wednesday: charity.wednesday || '',
              thursday: charity.thursday || '',
              friday: charity.friday || '',
              saturday: charity.saturday || '',
              sunday: charity.sunday || ''
            },
            donationInstructions: charity.donationinstructions,
            createdAt: new Date(),
            createdBy: user.id
          };
  
          const newCharityId = await charityService.create(newCharityData);
          createdCharities.push({ id: newCharityId, name: charity.name });
          console.log(`Charity created: ${charity.name}`);
        } catch (error) {
          console.error(`Failed to create charity: ${charity.name}`, error);
          failedCharities.push(charity.name);
        }
      }
  
      if (createdCharities.length > 0) {
        setSuccessMessage(`Successfully created ${createdCharities.length} ${createdCharities.length === 1 ? 'charity' : 'charities'}!`);
        toast.success(`Created ${createdCharities.length} ${createdCharities.length === 1 ? 'charity' : 'charities'} successfully`);
        await fetchAllCharities();
      }
  
      if (failedCharities.length > 0) {
        const failureMessage = `Failed to create: ${failedCharities.join(', ')}`;
        setError(failureMessage);
        toast.error(failureMessage);
      }
  
      setCsvData([]);
      setFileName('');
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      toast.error('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveFile = () => {
    setCsvData([]);
    setFileName('');
    setError('');
  };

  if (isLoading && !user) return <Loader />;
  if (!user) return <div className="access-denied">Please log in to access this page.</div>;

  return (
    <div className="app-container">
      <Header user={user} />
      <div className="main-content">
        <Sidebar />
        <div className="create-new-charity-container">
          <h1><FaHandHoldingHeart /> Create New Charity</h1>
          <CharityForm 
            error={error}
            successMessage={successMessage}
            handleSubmit={handleSubmit}
            handleFileUpload={handleFileUpload}
            fileName={fileName}
            handleRemoveFile={handleRemoveFile}
            csvData={csvData}
            isLoading={isLoading}
            charityMode={charityMode}
            setCharityMode={setCharityMode}
          />
          {csvData.length > 0 && charityMode === 'automatic' && (
            <CsvPreview csvData={csvData} />
          )}
          <div className="charity-cards-section">
          <h2><FaListAlt /> Existing Charities</h2>
          <CharityCards 
            charities={allCharities.map(charity => ({
              id: charity.id,
              name: charity.name,
              logoUrl: charity.logoUrl,
              description: charity.description,
              address: {
                street: charity.address.street,
                city: charity.address.city,
                state: charity.address.state,
                zipcode: charity.address.zipCode,
                country: charity.address.country,
                latitude: charity.address.latitude,
                longitude: charity.address.longitude
              },
              contactInfo: charity.contactInfo,
              acceptedItems: charity.acceptedItems,
              operatingHours: charity.operatingHours,
              createdAt: charity.createdAt
            }))} 
          />
        </div>
      </div>
    </div>
    <ToastContainer 
      position="bottom-right" 
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </div>
);
}

function CharityForm({ 
  error, 
  successMessage, 
  handleSubmit, 
  handleFileUpload, 
  fileName, 
  handleRemoveFile, 
  csvData, 
  isLoading, 
  charityMode, 
  setCharityMode 
}) {
  return (
    <div className="create-new-charity-form">
      <h2><FaFileInvoice /> New Charity Details</h2>
      
      {error && (
        <div className="error-message">
          <FaExclamationTriangle /> {error}
        </div>
      )}
      
      {successMessage && (
        <div className="success-message">
          <FaHandHoldingHeart /> {successMessage}
        </div>
      )}
      
      <div className="form-group">
        <label htmlFor="charity-mode">
          <FaListAlt /> Entry Mode
        </label>
        <select
          id="charity-mode"
          value={charityMode}
          onChange={(e) => setCharityMode(e.target.value)}
          className="mode-select"
        >
          <option value="automatic">Automatic (CSV Upload)</option>
          <option value="manual">Manual Entry</option>
        </select>
      </div>

      {charityMode === 'automatic' ? (
        <AutomaticCharityForm
          handleFileUpload={handleFileUpload}
          fileName={fileName}
          handleRemoveFile={handleRemoveFile}
        />
      ) : (
        <ManualCharityForm handleSubmit={handleSubmit} />
      )}

      <button 
        type="submit" 
        onClick={(e) => handleSubmit(e, charityMode === 'manual' ? ManualCharityForm.getCharity() : null)}
        disabled={isLoading || (charityMode === 'automatic' && csvData.length === 0)}
        className="submit-button"
      >
        {isLoading ? (
          <>
            <FaSpinner className="spinner" /> Creating...
          </>
        ) : (
          <>
            <FaHandHoldingHeart /> Create Charity
          </>
        )}
      </button>
    </div>
  );
}

function AutomaticCharityForm({ handleFileUpload, fileName, handleRemoveFile }) {
  return (
    <div className="form-group file-upload">
      <label htmlFor="csv-upload">
        <FaUpload /> Upload Charities CSV
      </label>
      <input
        type="file"
        id="csv-upload"
        accept=".csv"
        onChange={handleFileUpload}
        required
      />
      {fileName && (
        <div className="file-info">
          <FaFileAlt /> {fileName}
          <button 
            type="button" 
            onClick={handleRemoveFile} 
            className="remove-file"
            aria-label="Remove file"
          >
            <FaTrash />
          </button>
        </div>
      )}
    </div>
  );
}

function ManualCharityForm({ handleSubmit }) {
  const initialState = {
    name: '',
    logourl: '',
    street: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    phone: '',
    email: '',
    website: '',
    accepteditems: '',
    description: '',
    taxid: '',
    donationinstructions: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
    sunday: ''
  };

  const [charity, setCharity] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const validateField = (name, value) => {
    if (['name', 'street', 'city', 'state', 'country', 'description', 'donationinstructions'].includes(name)) {
      return value.trim() ? '' : `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    }

    switch (name) {
      case 'logourl':
      case 'website':
        return value.trim() ? 
          PATTERNS.URL.test(value) ? '' : 'Please enter a valid URL'
          : `${name === 'logourl' ? 'Logo URL' : 'Website'} is required`;
      
      case 'email':
        return value.trim() ? 
          PATTERNS.EMAIL.test(value) ? '' : 'Please enter a valid email address'
          : 'Email is required';
      
      case 'phone':
        return value.trim() ? 
          PATTERNS.PHONE.test(value.replace(/[\s-)(]/g, '')) ? '' : 'Please enter a valid phone number'
          : 'Phone number is required';
      
      case 'zipcode':
        return value.trim() ? 
          PATTERNS.ZIP.test(value) ? '' : 'Please enter a valid ZIP code'
          : 'ZIP code is required';
      
      case 'taxid':
        return value.trim() ? 
          PATTERNS.TAX_ID.test(value) ? '' : 'Please enter a valid Tax ID (XX-XXXXXXX)'
          : 'Tax ID is required';
      
      case 'accepteditems':
        return value.trim() ? '' : 'Please enter at least one accepted item';
      
      case 'monday':
      case 'tuesday':
      case 'wednesday':
      case 'thursday':
      case 'friday':
      case 'saturday':
      case 'sunday':
        if (!value) return ''; // Optional field
        return PATTERNS.TIME.test(value) ? 
          '' : 'Please use format: HH:MM AM/PM - HH:MM AM/PM';
      
      default:
        return '';
    }
  };

  const handleChange = (field, value) => {
    let processedValue = value;
    
    // Special handling for phone numbers
    if (field === 'phone') {
      processedValue = value.replace(/\D/g, '').slice(0, 10);
      if (processedValue.length >= 10) {
        processedValue = formatPhoneNumber(processedValue);
      }
    }

    // Special handling for Tax ID
    if (field === 'taxid') {
      processedValue = value.replace(/[^\d-]/g, '').slice(0, 10);
      if (processedValue.length >= 2 && !processedValue.includes('-')) {
        processedValue = processedValue.slice(0, 2) + '-' + processedValue.slice(2);
      }
    }

    setCharity(prev => ({ ...prev, [field]: processedValue }));
    
    if (touched[field]) {
      setErrors(prev => ({
        ...prev,
        [field]: validateField(field, processedValue)
      }));
    }
  };

  const handleBlur = (field) => {
    setTouched(prev => ({ ...prev, [field]: true }));
    setErrors(prev => ({
      ...prev,
      [field]: validateField(field, charity[field])
    }));
  };

  const formatTimeString = (timeStr) => {
    if (!timeStr) return '';
    
    // Remove extra spaces and ensure consistent format
    timeStr = timeStr.replace(/\s+/g, ' ').trim().toUpperCase();
    
    const parts = PATTERNS.TIME.exec(timeStr);
    if (parts) {
      const [_, h1, m1, ap1, h2, m2, ap2] = parts;
      return `${h1.padStart(2, '0')}:${m1} ${ap1} - ${h2.padStart(2, '0')}:${m2} ${ap2}`;
    }
    return timeStr;
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(charity).forEach(field => {
      const error = validateField(field, charity[field]);
      if (error) {
        newErrors[field] = error;
      }
    });

    setErrors(newErrors);
    setTouched(Object.keys(charity).reduce((acc, field) => ({...acc, [field]: true}), {}));

    return Object.keys(newErrors).length === 0;
  };

  ManualCharityForm.getCharity = () => {
    if (!validateForm()) {
      throw new Error('Please fix all validation errors before submitting');
    }

    // Format all time fields
    const formattedCharity = { ...charity };
    ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].forEach(day => {
      if (formattedCharity[day]) {
        formattedCharity[day] = formatTimeString(formattedCharity[day]);
      }
    });

    return formattedCharity;
  };

  const renderFormField = (field, label, type = 'text', icon = null, placeholder = '', required = true) => (
    <div className="form-group">
      <label htmlFor={field}>
        {icon} {label}{required && '*'}
      </label>
      <input
        id={field}
        type={type}
        value={charity[field]}
        onChange={(e) => handleChange(field, e.target.value)}
        onBlur={() => handleBlur(field)}
        placeholder={placeholder}
        className={errors[field] && touched[field] ? 'error' : ''}
        required={required}
      />
      {errors[field] && touched[field] && (
        <div className="error-text">
          <FaExclamationTriangle /> {errors[field]}
        </div>
      )}
    </div>
  );

  return (
    <form onSubmit={handleSubmit} className="manual-charity-form">
      <div className="form-section">
        <h3><FaBuilding /> Basic Information</h3>
        <div className="form-grid">
          {renderFormField('name', 'Charity Name', 'text', <FaHandHoldingHeart />, 'Enter charity name')}
          {renderFormField('logourl', 'Logo URL', 'url', <FaFileAlt />, 'https://example.com/logo.png')}
          {renderFormField('taxid', 'Tax ID', 'text', <FaFileInvoice />, 'XX-XXXXXXX')}
        </div>
      </div>

      <div className="form-section">
        <h3><FaMapMarkerAlt /> Address Information</h3>
        <div className="form-grid">
          {renderFormField('street', 'Street Address', 'text', <FaMapMarkerAlt />, '123 Main St')}
          {renderFormField('city', 'City', 'text', null, 'City')}
          {renderFormField('state', 'State', 'text', null, 'State')}
          {renderFormField('zipcode', 'ZIP Code', 'text', null, '12345')}
          {renderFormField('country', 'Country', 'text', null, 'Country')}
        </div>
      </div>

      <div className="form-section">
        <h3><FaPhone /> Contact Information</h3>
        <div className="form-grid">
          {renderFormField('phone', 'Phone Number', 'tel', <FaPhone />, '(555) 555-5555')}
          {renderFormField('email', 'Email Address', 'email', <FaEnvelope />, 'contact@charity.org')}
          {renderFormField('website', 'Website', 'url', <FaGlobe />, 'https://www.charity.org')}
        </div>
      </div>

      <div className="form-section">
        <h3><FaClock /> Operating Hours</h3>
        <p className="operating-hours-help">
          Format: HH:MM AM/PM - HH:MM PM (e.g., 9:00 AM - 5:00 PM)
        </p>
        <div className="operating-hours-grid">
          {['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map(day => (
            renderFormField(
              day,
              day.charAt(0).toUpperCase() + day.slice(1),
              'text',
              <FaClock />,
              '9:00 AM - 5:00 PM',
              false
            )
          ))}
        </div>
      </div>

      <div className="form-section">
        <h3><FaInfoCircle /> Additional Information</h3>
        <div className="form-grid">
          <div className="form-group full-width">
            <label htmlFor="accepteditems">
              <FaBoxes /> Accepted Items*
            </label>
            <input
              id="accepteditems"
              type="text"
              value={charity.accepteditems}
              onChange={(e) => handleChange('accepteditems', e.target.value)}
              onBlur={() => handleBlur('accepteditems')}
              placeholder="Enter accepted items (space-separated)"
              className={errors.accepteditems && touched.accepteditems ? 'error' : ''}
              required
            />
            {errors.accepteditems && touched.accepteditems && (
              <div className="error-text">
                <FaExclamationTriangle /> {errors.accepteditems}
              </div>
            )}
          </div>

          <div className="form-group full-width">
            <label htmlFor="description">
              <FaFileAlt /> Description*
            </label>
            <textarea
              id="description"
              value={charity.description}
              onChange={(e) => handleChange('description', e.target.value)}
              onBlur={() => handleBlur('description')}
              placeholder="Enter charity description"
              className={errors.description && touched.description ? 'error' : ''}
              required
              rows="4"
            />
            {errors.description && touched.description && (
              <div className="error-text">
                <FaExclamationTriangle /> {errors.description}
              </div>
            )}
          </div>

          <div className="form-group full-width">
            <label htmlFor="donationinstructions">
              <FaListAlt /> Donation Instructions*
            </label>
            <textarea
              id="donationinstructions"
              value={charity.donationinstructions}
              onChange={(e) => handleChange('donationinstructions', e.target.value)}
              onBlur={() => handleBlur('donationinstructions')}
              placeholder="Enter donation instructions"
              className={errors.donationinstructions && touched.donationinstructions ? 'error' : ''}
              required
              rows="4"
            />
            {errors.donationinstructions && touched.donationinstructions && (
              <div className="error-text">
                <FaExclamationTriangle /> {errors.donationinstructions}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}

function CsvPreview({ csvData }) {
  if (csvData.length === 0) return null;

  return (
    <div className="csv-preview">
      <h3><FaListAlt /> CSV Preview</h3>
      <div className="csv-table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Logo URL</th>
              <th>Address</th>
              <th>Contact Info</th>
              <th>Accepted Items</th>
              <th>Description</th>
              <th>Tax ID</th>
            </tr>
          </thead>
          <tbody>
            {csvData.map((row, index) => (
              <tr key={index}>
                <td>{row.name}</td>
                <td>
                  <img 
                    src={row.logourl} 
                    alt={`${row.name} logo`} 
                    className="preview-logo"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/placeholder-logo.png';
                    }}
                  />
                </td>
                <td>{`${row.street}, ${row.city}, ${row.state} ${row.zipcode}, ${row.country}`}</td>
                <td>{`${row.phone}, ${row.email}`}</td>
                <td>{row.accepteditems}</td>
                <td>{row.description}</td>
                <td>{row.taxid}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p className="preview-note">Showing all {csvData.length} charities</p>
    </div>
  );
}

function CharitiesList({ charities }) {
  return (
    <div className="all-charities">
      <h3><FaListAlt /> All Charities</h3>
      {charities.length > 0 ? (
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Contact Info</th>
                <th>Accepted Items</th>
                <th>Tax ID</th>
              </tr>
            </thead>
            <tbody>
              {charities.map((charity) => (
                <tr key={charity.id}>
                  <td>{charity.name}</td>
                  <td>{`${charity.contactInfo.phone}, ${charity.contactInfo.email}`}</td>
                  <td>{charity.acceptedItems.join(', ')}</td>
                  <td>{charity.taxId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="no-charities">No charities found</p>
      )}
    </div>
  );
}

// Helper function to format phone numbers
function formatPhoneNumber(phoneNumberString) {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return phoneNumberString;
}

export default CreateNewCharity;