// src/services/messageService.js

class MessageService {
  constructor() {
    this.authToken = btoa(`${process.env.REACT_APP_TWILIO_ACCOUNT_SID}:${process.env.REACT_APP_TWILIO_AUTH_TOKEN}`);
    this.appBaseUrl = 'https://app.donatingsimplified.com';
  }

  // ============ Core Messaging Functionality ============

  /**
   * Format phone number to E.164 format
   * @private
   * @param {string} phone - The phone number to format
   * @returns {string} E.164 formatted phone number
   */
  formatPhoneNumber(phone) {
    if (!phone) {
      throw new Error('Phone number is required');
    }

    // Remove all non-numeric characters
    let cleaned = phone.replace(/\D/g, '');

    // Handle US numbers without country code
    if (cleaned.length === 10) {
      cleaned = '1' + cleaned;
    }

    // Remove leading 1 if present to standardize
    if (cleaned.startsWith('1')) {
      cleaned = cleaned.substring(1);
    }

    // Validate length after cleaning (should be 10 digits for US numbers)
    if (cleaned.length !== 10) {
      throw new Error('Invalid phone number length');
    }

    // Format to E.164
    return `+1${cleaned}`;
  }

  /**
   * Validate phone number format (E.164)
   * @private
   * @param {string} phone - The phone number to validate
   * @returns {boolean} Whether the phone number is valid
   */
  validatePhoneNumber(phone) {
    const phoneRegex = /^\+[1-9]\d{1,14}$/;
    return phoneRegex.test(phone);
  }

  /**
   * Send a message using Twilio
   * @private
   * @param {string} userPhone - The recipient's phone number
   * @param {string} messageBody - The message content to send
   * @returns {Promise<Object>} Result of the message sending operation
   */
  async sendMessage(userPhone, messageBody) {
    try {
      // Format the phone number before validation
      const formattedPhone = this.formatPhoneNumber(userPhone);
      
      if (!this.validatePhoneNumber(formattedPhone)) {
        throw new Error('Invalid phone number format after formatting');
      }

      const formData = new URLSearchParams();
      formData.append('To', formattedPhone);
      formData.append('From', process.env.REACT_APP_TWILIO_PHONE_NUMBER);
      formData.append('Body', messageBody);

      const response = await fetch(
        `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_ACCOUNT_SID}/Messages.json`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Basic ${this.authToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData
        }
      );

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Failed to send message');
      }

      return {
        success: true,
        messageId: data.sid
      };
    } catch (error) {
      console.error('Error sending message:', error);
      throw new Error(`Failed to send message: ${error.message}`);
    }
  }

  // ============ Formatting Utilities ============

  /**
   * Format currency values consistently
   * @private
   * @param {number} amount - The amount to format
   * @returns {string} Formatted currency string
   */
  formatCurrency(amount) {
    if (typeof amount !== 'number') return 'N/A';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }).format(amount);
  }

  /**
   * Format date consistently
   * @private
   * @param {string|Date} date - The date to format
   * @returns {string} Formatted date string
   */
  formatDate(date) {
    if (!date) return 'N/A';
    return new Date(date).toLocaleDateString();
  }

  /**
   * Format date and time consistently
   * @private
   * @param {string|Date} datetime - The datetime to format
   * @returns {string} Formatted datetime string
   */
  formatDateTime(datetime) {
    if (!datetime) return 'N/A';
    return new Date(datetime).toLocaleString();
  }

  /**
   * Generate URL for specific pages
   * @private
   * @param {string} path - The path to append to base URL
   * @param {string} [id] - Optional ID to append to path
   * @returns {string} Complete URL
   */
  getPageUrl(path, id = '') {
    return `${this.appBaseUrl}/${path}${id ? `/${id}` : ''}`;
  }

  // ============ Quote Notifications ============

  /**
   * Send notification when a new quote is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendNewQuoteNotification(userPhone, quoteDetails) {
    const message = this.formatNewQuoteMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for new quote
   * @private
   */
  formatNewQuoteMessage(quoteDetails) {
    return `
New Shipping Quote Created!

Quote ID: ${quoteDetails.id}
Item: ${quoteDetails.itemDescription}
Pallets: ${quoteDetails.palletCount}
Total Amount: ${this.formatCurrency(quoteDetails.amount)}
Valid Until: ${this.formatDate(quoteDetails.validityPeriod)}

Review your quote at ${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is approved
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteApprovalNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteApprovalMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote approval
   * @private
   */
  formatQuoteApprovalMessage(quoteDetails) {
    return `
Your Shipping Quote Has Been Approved!

Quote ID: ${quoteDetails.id}
Distribution Center: ${quoteDetails.distributionCenterName}
Amount: ${this.formatCurrency(quoteDetails.amount)}
Price Per Pallet: ${this.formatCurrency(quoteDetails.pricePerPallet)}
Approved By: ${quoteDetails.approverName}
Approval Date: ${this.formatDate(quoteDetails.approvalTimestamp)}

View your approved quote at ${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is rejected
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteRejectionNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteRejectionMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote rejection
   * @private
   */
  formatQuoteRejectionMessage(quoteDetails) {
    return `
Your Shipping Quote Has Been Rejected

Quote ID: ${quoteDetails.id}
Distribution Center: ${quoteDetails.distributionCenterName}
Rejected By: ${quoteDetails.rejectedBy}
Rejection Date: ${this.formatDate(quoteDetails.rejectionTimestamp)}
Reason: ${quoteDetails.rejectionReason}

Please review the rejection details and submit a new quote if needed:
${this.getPageUrl('quotes', quoteDetails.id)}
    `.trim();
  }

  /**
   * Send notification when a quote is completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} quoteDetails - Quote details
   */
  async sendQuoteCompletionNotification(userPhone, quoteDetails) {
    const message = this.formatQuoteCompletionMessage(quoteDetails);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for quote completion
   * @private
   */
  formatQuoteCompletionMessage(quoteDetails) {
    return `
Shipping Quote Completed!

Quote ID: ${quoteDetails.id}
Distribution Center: ${quoteDetails.distributionCenterName}
Item: ${quoteDetails.itemDescription}
Total Amount: ${this.formatCurrency(quoteDetails.amount)}
Completion Date: ${this.formatDate(quoteDetails.completedAt)}

View your completed quote in the Reports section:
${this.getPageUrl('reports')}
    `.trim();
  }

  // ============ Logistics Notifications ============

  /**
   * Send notification when a pickup approval request is created
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Pickup request details
   */
  async sendPickupApprovalRequestNotification(userPhone, details) {
    const message = this.formatPickupApprovalRequestMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for pickup approval request
   * @private
   */
  formatPickupApprovalRequestMessage(details) {
    return `
Pickup Approval Request

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Requested Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}

Please review and approve the pickup request:
${this.getPageUrl('logistics/approvals')}

Note: This request requires your approval within 24 hours.
    `.trim();
  }

  /**
   * Send notification when a pickup request decision (approval/rejection) is made
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Decision details
   * @param {string} details.status - 'approved' or 'rejected'
   * @param {string} details.approverName - Name of the person who made the decision
   * @param {string} details.itemDescription - Description of items
   * @param {string} details.distributionCenterName - Name of distribution center
   * @param {string} details.charityName - Name of charity
   * @param {number} details.palletQuantity - Number of pallets
   * @param {string} details.pickupDateTime - Pickup date and time
   * @param {string} details.deliveryDate - Delivery date
   */
  async sendPickupRequestDecisionNotification(userPhone, details) {
    const message = details.status === 'approved' 
      ? this.formatPickupRequestApprovalMessage(details)
      : this.formatPickupRequestRejectionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for pickup request approval
   * @private
   */
  formatPickupRequestApprovalMessage(details) {
    return `
Pickup Request Approved!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Confirmed Pickup: ${this.formatDateTime(details.pickupDateTime)}
Delivery Date: ${this.formatDate(details.deliveryDate)}
Approved By: ${details.approverName}

Your pickup request has been approved. Please ensure shipping and charity acceptance remain available for the scheduled pickup date.

View the approved pickup details:
${this.getPageUrl('logistics/tasks', details.taskId)}
    `.trim();
  }

  /**
   * Format message for pickup request rejection
   * @private
   */
  formatPickupRequestRejectionMessage(details) {
    return `
Pickup Request Rejected

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Charity: ${details.charityName}
Pallet Quantity: ${details.palletQuantity}
Requested Pickup: ${this.formatDateTime(details.pickupDateTime)}
Rejected By: ${details.approverName}

Your pickup request has been rejected. Please contact the distribution center for more information or submit a new request.

View the request details:
${this.getPageUrl('logistics/tasks', details.taskId)}
    `.trim();
  }

  // ============ Donation Notifications ============

  /**
   * Send notification when a donation is marked as completed
   * @param {string} userPhone - The recipient's phone number
   * @param {Object} details - Donation completion details
   */
  async sendDonationCompletionNotification(userPhone, details) {
    const message = this.formatDonationCompletionMessage(details);
    return this.sendMessage(userPhone, message);
  }

  /**
   * Format message for completed donation
   * @private
   */
  formatDonationCompletionMessage(details) {
    const charitiesList = details.charities
      .map(charity => `- ${charity.name} (${charity.palletCount} pallets)`)
      .join('\n');

    return `
Donation Completed!

Item: ${details.itemDescription}
Distribution Center: ${details.distributionCenterName}
Fair Market Value: ${this.formatCurrency(details.fmvAmount)}
Total Pallets: ${details.totalPallets}
Completion Date: ${this.formatDate(details.completionDate)}

Receiving Charities:
${charitiesList}

Your tax receipts and completion documents are ready:
${this.getPageUrl('reports')}

Thank you for your generous donation! Your items have been successfully delivered to the charities.
    `.trim();
  }
}

export const messageService = new MessageService();